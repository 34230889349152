import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-header" }
const _hoisted_2 = { class: "widget-header-content" }
const _hoisted_3 = { class: "widget-header-title tiny-container no-drag" }
const _hoisted_4 = { class: "widget-body" }
const _hoisted_5 = { class: "widget-body-content" }
const _hoisted_6 = {
  ref: "content",
  class: "flashcard-content tiny-container no-drag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill = _resolveComponent("quill")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "widget-avatar no-drag",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIconChanged && _ctx.onIconChanged(...args)))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(`${ 'las ' + _ctx.widget.specific.icon }`)
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_quill, {
            theme: "snow",
            content: _ctx.widgetFound.specific.title,
            "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.widgetFound.specific.title) = $event)),
            options: _ctx.quillOptions,
            "content-type": "text",
            ref: "quillEditor",
            placeholder: "Add title",
            spellcheck: "false",
            onUndo: _cache[2] || (_cache[2] = ($event: any) => (_ctx.titleChanged())),
            onEditorChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.titleChanged())),
            onPaste: _cache[4] || (_cache[4] = ($event: any) => (_ctx.titleChanged())),
            "read-only": _ctx.readonly
          }, null, 8, ["content", "options", "read-only"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_quill, {
            theme: "snow",
            content: _ctx.widgetFound.specific.content,
            "onUpdate:content": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.widgetFound.specific.content) = $event)),
            options: _ctx.quillOptions,
            "content-type": "text",
            ref: "quillEditor",
            placeholder: "Content",
            spellcheck: "false",
            onUndo: _cache[6] || (_cache[6] = ($event: any) => (_ctx.contentChanged())),
            onEditorChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.contentChanged())),
            onPaste: _cache[8] || (_cache[8] = ($event: any) => (_ctx.contentChanged())),
            "read-only": _ctx.readonly
          }, null, 8, ["content", "options", "read-only"])
        ], 512)
      ])
    ])
  ], 64))
}