// INFO WIDGETS
export const CODE: string = 'code'
export const FILE_UPLOAD: string = 'file-upload'
export const FILE_UPLOAD_STYLED: string = 'file-upload-styled'
export const FLASHCARD: string = 'flashcard'
export const HEADING: string = 'heading'
export const IFRAME_EMBEDDED: string = 'iframe-embedded'
export const IFRAME_STYLED: string = 'iframe-styled'
export const IMAGE_CARD: string = 'image-card'
export const IMAGE_CARD_STYLED: string = 'image-card-styled'
export const SHAPE: string = 'shape'
export const TEXT_CARD: string = 'text-card'
export const TEXT_CARD_STYLED: string = 'text-card-styled'
export const VIDEO_EMBEDDED: string = 'video-embedded'
export const VIDEO_EMBEDDED_STYLED: string = 'video-embedded-styled'
export const ICONFINDER: string = 'iconfinder'
export const IMAGE_VIEW_360: string = 'image-view-360'

// ACTION WIDGETS
export const DRAG_AND_DROP_GROUP: string = 'drag-and-drop-group'
export const DRAG_AND_DROP_ORDER: string = 'drag-and-drop-order'
export const QUESTION_SINGLE_CHOICE: string = 'question-single-choice'
export const QUESTION_TEAM_READINESS: string = 'question-team-readiness'
export const QUESTION_MULTIPLE_CHOICE: string = 'question-multiple-choice'
export const QUESTION_SHORT_ANSWER: string = 'question-short-answer'
export const QUESTION_SHORT_ANSWER_AI_SCORED: string = 'question-short-answer-ai-scored'
export const QUESTION_FREE_ANSWER: string = 'question-free-answer'
export const REFLECTION_POST_IT: string = 'reflection-post-it'
export const HOTSPOT_SQUARE: string = 'hotspot-square'
export const HOTSPOT_CIRCLE: string = 'hotspot-circle'
export const HOTSPOT_TRIANGLE: string = 'hotspot-triangle'

export const CHATTY_AVATAR_WOMAN: string = 'chatty-avatar-woman'
export const CHATTY_AVATAR_MAN:string = 'chatty-avatar-man'
export const CHATTY_AVATAR_ROBOT:string = 'chatty-avatar-robot'

export const widgetType: Array<string> = [
  CODE,
  DRAG_AND_DROP_GROUP,
  DRAG_AND_DROP_ORDER,
  FILE_UPLOAD,
  FILE_UPLOAD_STYLED,
  FLASHCARD,
  HEADING,
  IFRAME_EMBEDDED,
  IFRAME_STYLED,
  IMAGE_CARD,
  IMAGE_CARD_STYLED,
  TEXT_CARD,
  TEXT_CARD_STYLED,
  VIDEO_EMBEDDED,
  VIDEO_EMBEDDED_STYLED,
  QUESTION_SINGLE_CHOICE,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SHORT_ANSWER,
  QUESTION_FREE_ANSWER,
  REFLECTION_POST_IT,
  CHATTY_AVATAR_WOMAN,
  CHATTY_AVATAR_MAN,
  CHATTY_AVATAR_ROBOT
]

export const infoWidgetTypes: Array<string> = [
  CODE,
  FILE_UPLOAD,
  FILE_UPLOAD_STYLED,
  FLASHCARD,
  HEADING,
  IFRAME_EMBEDDED,
  IFRAME_STYLED,
  IMAGE_CARD,
  IMAGE_CARD_STYLED,
  TEXT_CARD,
  TEXT_CARD_STYLED,
  VIDEO_EMBEDDED,
  VIDEO_EMBEDDED_STYLED
]

export const actionWidgetTypes: Array<string> = [
  DRAG_AND_DROP_GROUP,
  DRAG_AND_DROP_ORDER,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEAM_READINESS,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SHORT_ANSWER,
  QUESTION_FREE_ANSWER,
  QUESTION_SHORT_ANSWER_AI_SCORED,
  REFLECTION_POST_IT
]
