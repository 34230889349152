
import { computed, defineComponent, ref, shallowRef, watch } from 'vue'
import { Codemirror } from 'vue-codemirror'
import { StreamLanguage } from '@codemirror/language'
import { oneDark } from '@codemirror/theme-one-dark'
import { brainfuck } from '@codemirror/legacy-modes/mode/brainfuck'
import { clojure } from '@codemirror/legacy-modes/mode/clojure'
import { coffeeScript } from '@codemirror/legacy-modes/mode/coffeescript'
import { cpp } from '@codemirror/lang-cpp'
import { css } from '@codemirror/lang-css'
import { dockerFile } from '@codemirror/legacy-modes/mode/dockerfile'
import { erlang } from '@codemirror/legacy-modes/mode/erlang'
import { go } from '@codemirror/legacy-modes/mode/go'
import { html } from '@codemirror/lang-html'
import { java } from '@codemirror/lang-java'
import { javascript } from '@codemirror/lang-javascript'
import { json } from '@codemirror/lang-json'
import { julia } from '@codemirror/legacy-modes/mode/julia'
import { lezer } from '@codemirror/lang-lezer'
import { lua } from '@codemirror/legacy-modes/mode/lua'
import { markdown } from '@codemirror/lang-markdown'
import { nginx } from '@codemirror/legacy-modes/mode/nginx'
import { pascal } from '@codemirror/legacy-modes/mode/pascal'
import { perl } from '@codemirror/legacy-modes/mode/perl'
import { php } from '@codemirror/lang-php'
import { powerShell } from '@codemirror/legacy-modes/mode/powershell'
import { python } from '@codemirror/lang-python'
import { r } from '@codemirror/legacy-modes/mode/r'
import { ruby } from '@codemirror/legacy-modes/mode/ruby'
import { rust } from '@codemirror/legacy-modes/mode/rust'
import { scheme } from '@codemirror/legacy-modes/mode/scheme'
import { shell } from '@codemirror/legacy-modes/mode/shell'
import { sql } from '@codemirror/lang-sql'
import { stylus } from '@codemirror/legacy-modes/mode/stylus'
import { swift } from '@codemirror/legacy-modes/mode/swift'
import { tcl } from '@codemirror/legacy-modes/mode/tcl'
import { toml } from '@codemirror/legacy-modes/mode/toml'
import { vb } from '@codemirror/legacy-modes/mode/vb'
import { vbScript } from '@codemirror/legacy-modes/mode/vbscript'
import { xml } from '@codemirror/legacy-modes/mode/xml'
import { yaml } from '@codemirror/legacy-modes/mode/yaml'
import { Code } from '@/core/helpers/template/WidgetInterface'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    widget: { type: Object as () => Code, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    Codemirror
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    // const currentWidget = ref(props.widget as Code) as any
    const languages = ['brainfuck', 'clojure', 'coffeescript', 'cpp', 'css', 'dockerfile', 'erlang', 'go', 'html', 'java',
      'javascript', 'json', 'julia', 'lezer', 'lua', 'markdown', 'mysql', 'nginx', 'pascal', 'perl', 'pgsql', 'php', 'powershell',
      'python', 'r', 'ruby', 'rust', 'scheme', 'shell', 'sql', 'stylus', 'swift', 'tcl', 'toml', 'vb', 'vbscript', 'xml', 'yaml']

    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))

    // Codemirror EditorView instance ref
    const view = shallowRef()
    const handleReady = (payload) => {
      view.value = payload.view
    }

    const selectLanguage = () => {
      if (!widgetFound.value) {
        return javascript()
      }
      if (!widgetFound.value.specific) {
        return javascript()
      }
      const language = widgetFound.value.specific.language
      switch (language) {
        case 'brainfuck':
          // @ts-ignore
          return StreamLanguage.define(brainfuck)
        case 'clojure':
          // @ts-ignore
          return StreamLanguage.define(clojure)
        case 'coffeescript':
          // @ts-ignore
          return StreamLanguage.define(coffeeScript)
        case 'cpp':
          // @ts-ignore
          return cpp()
        case 'css':
          // @ts-ignore
          return css()
        case 'dockerfile':
          // @ts-ignore
          return StreamLanguage.define(dockerFile)
        case 'erlang':
          // @ts-ignore
          return StreamLanguage.define(erlang)
        case 'go':
          // @ts-ignore
          return StreamLanguage.define(go)
        case 'html':
          // @ts-ignore
          return html()
        case 'java':
          // @ts-ignore
          return java()
        case 'javascript':
          // @ts-ignore
          return javascript()
        case 'json':
          // @ts-ignore
          return json()
        case 'julia':
          // @ts-ignore
          return StreamLanguage.define(julia)
        case 'lezer':
          // @ts-ignore
          return lezer()
        case 'lua':
          // @ts-ignore
          return StreamLanguage.define(lua)
        case 'markdown':
          // @ts-ignore
          return markdown()
        case 'nginx':
          // @ts-ignore
          return StreamLanguage.define(nginx)
        case 'pascal':
          // @ts-ignore
          return StreamLanguage.define(pascal)
        case 'perl':
          // @ts-ignore
          return StreamLanguage.define(perl)
        case 'php':
          // @ts-ignore
          return php()
        case 'powershell':
          // @ts-ignore
          return StreamLanguage.define(powerShell)
        case 'python':
          // @ts-ignore
          return python()
        case 'r':
          // @ts-ignore
          return StreamLanguage.define(r)
        case 'ruby':
          // @ts-ignore
          return StreamLanguage.define(ruby)
        case 'rust':
          // @ts-ignore
          return StreamLanguage.define(rust)
        case 'scheme':
          // @ts-ignore
          return StreamLanguage.define(scheme)
        case 'shell':
          // @ts-ignore
          return StreamLanguage.define(shell)
        case 'sql':
        case 'mysql':
        case 'pgsql':
          // @ts-ignore
          return sql()
        case 'stylus':
          // @ts-ignore
          return StreamLanguage.define(stylus)
        case 'swift':
          // @ts-ignore
          return StreamLanguage.define(swift)
        case 'tcl':
          // @ts-ignore
          return StreamLanguage.define(tcl)
        case 'toml':
          // @ts-ignore
          return StreamLanguage.define(toml)
        case 'vb':
          // @ts-ignore
          return StreamLanguage.define(vb)
        case 'vbscript':
          // @ts-ignore
          return StreamLanguage.define(vbScript)
        case 'xml':
          // @ts-ignore
          return StreamLanguage.define(xml)
        case 'yaml':
          // @ts-ignore
          return StreamLanguage.define(yaml)
        default:
          return php()
      }
    }

    const extensions = ref([selectLanguage()]) as any

    if (widgetFound.value && widgetFound.value.specific && widgetFound.value.specific.color === 'dark') {
      extensions.value.push(oneDark)
    }

    const onLanguageChanged = (language) => {
      widgetFound.value.specific.language = language
      extensions.value = [selectLanguage()]

      if (widgetFound.value && widgetFound.value.specific && widgetFound.value.specific.color === 'dark') {
        console.log(oneDark)
        extensions.value.push(oneDark)
      }

      persistWidget()
    }

    watch(widgetFound, () => {
      extensions.value = [selectLanguage()]
      if (widgetFound.value && widgetFound.value.specific && widgetFound.value.specific.color === 'dark') {
        extensions.value.push(oneDark)
      }
    }, { deep: true })

    let persistTimeout = 0 as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        console.log('Persisting widget...')
        const currentWidgetInstance = widgetFound.value
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widget: currentWidgetInstance
        }
        socket.value.emit('EDIT_WIDGET', {
          eventType: 'EDIT_WIDGET',
          payload: socketPayload
        })
      }, 1000)
    }

    return {
      widgetFound,
      extensions,
      languages,
      solvingMode,
      handleReady,
      onLanguageChanged,
      persistWidget
    }
  }
})
