
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { QuestionShortAnswer } from '@/core/helpers/template/WidgetInterface'
// import Editor from '@tinymce/tinymce-vue'
import { useStore } from 'vuex'
import { QuillEditor as quill } from '@vueup/vue-quill'

export default defineComponent({
  name: 'question-short-answer',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionShortAnswer, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    quill
    // editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const questionValue = ref('')
    const dynamicQuestionValue = computed(() => props.widget.specific.question)
    const quillEditor = ref()
    watch(dynamicQuestionValue, () => {
      questionValue.value = dynamicQuestionValue.value
    })
    onMounted(() => {
      questionValue.value = props.widget.specific.question
    })
    const persistWidget = () => {
      console.log('Persisting widget...')
      const currentWidgetInstance = widgetFound.value
      const text = quillEditor.value.editor.__quill.getText()
      currentWidgetInstance.specific.question = text
      const alteredContent = widgetFound.value.textToSpeech.content !== widgetFound.value.specific.question.replace(/(<([^>]+)>)/gi, '')
      if (alteredContent) {
        widgetFound.value.textToSpeech.content = widgetFound.value.specific.question.replace(/(<([^>]+)>)/gi, '')
        widgetFound.value.textToSpeech.state = 'not-processed'
      }
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }
    const quillOptions = ref({
      modules: {
        toolbar: false
      }
    })

    return {
      widgetFound,
      editorSettings,
      solvingMode,
      getApiKey,
      persistWidget,
      questionValue,
      quillOptions,
      quillEditor
    }
  }
})
