import {
  CODE,
  DRAG_AND_DROP_GROUP,
  DRAG_AND_DROP_ORDER,
  FILE_UPLOAD, HEADING,
  // IFRAME,
  // IFRAME_STYLED,
  IMAGE_CARD,
  // IMAGE_CARD_STYLED,
  // QUESTION_FREE_ANSWER,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SHORT_ANSWER,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEAM_READINESS,
  // TEXT_CARD,
  TEXT_CARD_STYLED,
  VIDEO_EMBEDDED,
  ICONFINDER,
  QUESTION_SHORT_ANSWER_AI_SCORED,
  IFRAME_EMBEDDED,
  IMAGE_VIEW_360,
  SHAPE,
  REFLECTION_POST_IT,
  HOTSPOT_SQUARE,
  HOTSPOT_CIRCLE
} from '@/core/helpers/template/widgetType'

interface MenuItem {
    type: string;
    name: string;
    icon: string;
    widgets?: Array<MenuItem>;
}

export const menuInfoWidgets: Array<MenuItem> = [

  {
    type: TEXT_CARD_STYLED,
    name: 'Shape',
    icon: 'icons8-rounded-square.svg',
    widgets: [
      {
        type: SHAPE,
        name: 'Circle',
        icon: 'icons8-circle.svg'
      },
      {
        type: SHAPE,
        name: 'Triangle',
        icon: 'icons8-triangle.svg'
      }
    ]
  },
  {
    type: HEADING,
    name: 'Heading',
    icon: 'icons8-header-1.svg'
  },
  {
    type: FILE_UPLOAD,
    name: 'File upload',
    icon: 'icons8-new-copy.svg'
  },
  {
    type: IMAGE_VIEW_360,
    name: '360 Image',
    icon: 'rotation-360-icon.svg'
  },
  // {
  //   type: FLASHCARD,
  //   name: 'Callout',
  //   icon: 'icons8-megaphone.svg'
  // },

  {
    type: IFRAME_EMBEDDED,
    name: 'Embedded iframe',
    icon: 'iframe-icon.svg'
  },
  // {
  //   type: IFRAME_STYLED,
  //   name: 'Styled embedded iframe',
  //   icon: 'la-window-maximize'
  // },
  {
    type: IMAGE_CARD,
    name: 'Image',
    icon: 'icons8-image.svg'
  },
  {
    type: ICONFINDER,
    name: 'Iconfinder',
    icon: 'icons8-iconfinder.svg'
  },
  // {
  //   type: IMAGE_CARD_STYLED,
  //   name: 'Styled image card',
  //   icon: 'la-image'
  // },
  // {
  //   type: TEXT_CARD,
  //   name: 'Text card',
  //   icon: 'la-id-card'
  // },

  {
    type: VIDEO_EMBEDDED,
    name: 'Embedded video',
    icon: 'icons8-video-playlist.svg'
  },
  {
    type: CODE,
    name: 'Code',
    icon: 'icons8-placeholder-thumbnail-xml.svg'
  }

  /*,  {
    type: VIDEO_EMBEDDED_STYLED,
    name: 'Styled embedded video',
    icon: 'la-play-circle'
  } */
]

export const menuActionWidgets: Array<MenuItem> = [
  {
    type: QUESTION_SINGLE_CHOICE,
    name: 'Single choice',
    icon: 'icons8-single-choice.svg',
    widgets: [
      {
        type: QUESTION_SINGLE_CHOICE,
        name: 'Single choice',
        icon: 'icons8-single-choice.svg'
      },
      {
        type: QUESTION_TEAM_READINESS,
        name: 'Team readiness',
        icon: 'icons8-single-choice.svg'
      }
    ]
  },
  {
    type: QUESTION_MULTIPLE_CHOICE,
    name: 'Multiple choice',
    icon: 'icons8-multiple-choice.svg'
  },
  {
    type: QUESTION_SHORT_ANSWER,
    name: 'Short answer',
    icon: 'icons8-pencil.svg',
    widgets: [
      {
        type: QUESTION_SHORT_ANSWER,
        name: 'Exact match',
        icon: 'icons8-pencil.svg'
      },
      {
        type: QUESTION_SHORT_ANSWER_AI_SCORED,
        name: 'AI Scored',
        icon: 'builder-ai-scored.svg'
      }
    ]
  },

  {
    type: DRAG_AND_DROP_GROUP,
    name: 'Group',
    icon: 'icons8-space-before-paragraph.svg'
  },
  {
    type: DRAG_AND_DROP_ORDER,
    name: 'Order',
    icon: 'icons8-descending-sorting.svg'
  },
  {
    type: REFLECTION_POST_IT,
    name: 'Sticky note',
    icon: 'icons8-sheet.svg'
  },
  {
    type: HOTSPOT_SQUARE,
    name: 'Hotspot Square',
    icon: 'icons8-hotspot.svg',
    widgets: [
      {
        type: HOTSPOT_CIRCLE,
        name: 'Hotspot Circle',
        icon: 'icons8-hotspot.svg'
      }
    ]
  }
  // {
  //   type: QUESTION_FREE_ANSWER,
  //   name: 'Free answer question',
  //   icon: 'la-comment'
  // }
]
