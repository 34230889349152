import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill = _resolveComponent("quill")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["widget-label", _ctx.widgetFound.specific.color])
    }, "Post It", 2),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["widget-body", { 'no-drag': !_ctx.isDisabled,  [_ctx.widget.specific.color] : true }]),
      style: {"overflow-y":"scroll"}
    }, [
      _createVNode(_component_quill, {
        theme: "snow",
        style: {"height":"100%"},
        content: _ctx.widgetFound.specific.content,
        "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.widgetFound.specific.content) = $event)),
        options: _ctx.quillOptions,
        "content-type": "text",
        ref: "quillEditor",
        placeholder: "Add some default content if needed",
        spellcheck: "false",
        onEditorChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.persistWidget())),
        "read-only": _ctx.solvingMode || _ctx.readonly
      }, null, 8, ["content", "options", "read-only"])
    ], 2)), [
      [_directive_click_outside, _ctx.vcoConfig]
    ])
  ], 64))
}