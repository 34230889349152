
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import store from '@/store'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { ElNotification } from 'element-plus'
import { Widget } from '@/core/helpers/template/TemplateInterface'
import { uuid } from 'vue-uuid'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
// import { View } from '@element-plus/icons-vue'
import { isString } from 'element-plus/lib/utils/util'
import { buildTemplateFileUri } from '@/core/mc-admin-helpers/activity-template'

export default {
  name: 'builder-controls-menu',
  methods: { buildTemplateFileUri, isString },
  // components: { View },
  props: {
    template: { type: Object, required: true }
  },
  setup (props) {
    const emitter = inject('emitter') as any // Inject `emitter`
    const solvingModeSwitch = ref(false)
    const robotModeSwitch = ref(false)
    const showGridLinesSwitch = ref(true)
    const propTemplate = ref() as any
    const fullscreen = ref(false)
    const socket = computed(() => store.getters.getSocket)
    const sections = computed(() => store.getters.getSections)
    const mobileChecked = computed(() => store.getters.getMobileViewMode)
    const regenerateIsLoading = ref(false)
    const regenerateSectionInSelectMode = computed(() => store.getters.getRegenerateSectionInSelectMode)
    const selectedPages = ref()
    const checkAll = ref(false)
    const indeterminate = ref(false)
    const textContentVisible = ref(false)
    const textContentPage = ref()
    const textContent = ref()
    const handleCheckAll = (val) => {
      indeterminate.value = false
      if (val) {
        selectedPages.value = selectedAiFeederItem.value(selectedSourceType.value, selectedSource.value).map(item => item.pageNumber)
      } else {
        selectedPages.value = []
      }
    }
    const currentAccount = computed(() => store.getters.currentAccount)

    watch(selectedPages, (val) => {
      if (val.length === 0) {
        checkAll.value = false
        indeterminate.value = false
      } else if (val.length === selectedAiFeederItem.value(selectedSourceType.value, selectedSource.value).length) {
        checkAll.value = true
        indeterminate.value = false
      } else {
        indeterminate.value = true
      }
    })
    const handleViewButtonClick = (item) => {
      textContentPage.value = item.pageNumber
      textContentVisible.value = true
      textContent.value = item.content
    }
    const aiSourceChecked = ref(false)
    const aiFeederItems = computed(() => store.getters.getAiFeederItems)
    const selectedSource = ref()
    const selectedSourceType = ref()
    const selectedAiFeederItem = computed(() => {
      return (type, uuid) => {
        const aiFeederItem = aiFeederItems.value.find(item => item.uuid === uuid)

        if (!aiFeederItem) {
          return null // Handle the case when the aiFeederItem with the given uuid is not found
        }

        switch (type) {
          case 'text':
            return aiFeederItem.text
          case 'image':
            return aiFeederItem.generatedImages
          default:
            return null // Handle the case when an invalid type is provided
        }
      }
    })
    const truncateLabel = (name) => {
      const maxLength = 15
      if (name.length <= maxLength) {
        return name
      } else {
        return name.substring(0, maxLength) + '...'
      }
    }
    const activeSection = computed(() => store.getters.getActiveSection)
    const customInstructions = ref()
    const regenerateSectionWidgetsList = computed(() => store.getters.getActiveSectionRegenerateSection)

    function onRegenerateClicked () {
      regenerateIsLoading.value = true
      store.commit(Mutations.UPDATE_REGENERATE_SECTION_IN_SELECT_MODE, false)

      const payload = {} as any
      payload.templateUuid = activityTemplate.value.activityTemplateUuid
      payload.sectionUuid = activeSection.value.sectionUuid
      payload.data = {
        prompt: customInstructions.value,
        aiSource: aiSourceChecked.value,
        sourceUuid: selectedSource.value,
        sourceType: selectedSourceType.value,
        sourcePages: selectedPages.value,
        regenerateSectionList: regenerateSectionWidgetsList.value
      }
      console.log(payload)

      store.dispatch(Actions.API_REGENERATE_SECTION, payload).then((response) => {
        console.log('response success', response)
        // store.commit(Mutations.UPDATE_WIDGET_EDIT_WIDGET, response.payload.widget)
        regenerateIsLoading.value = false
      }).catch((response) => {
        store.commit(Mutations.UPDATE_REGENERATE_SECTION_IN_SELECT_MODE, false)

        console.log('error', response)
        regenerateIsLoading.value = false
      }).finally(() => {
        store.commit(Mutations.UPDATE_REGENERATE_SECTION_IN_SELECT_MODE, false)

        regenerateIsLoading.value = false
      })
    }
    const onKeydownPressed = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    }
    const activityTemplate = computed(() => {
      return store.getters.getActivityTemplate
    })
    let sectionsCount = 0

    emitter.on('clicked-outside-open-ai-drawer-builder', () => {
      if (robotModeSwitch.value) {
        robotModeSwitch.value = false
      }
    })
    const solvingModeSwitchOnClick = () => {
      if (solvingModeSwitch.value) {
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, false)
        // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, !value)
        solvingModeSwitch.value = false
      } else {
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, true)
        solvingModeSwitch.value = true
      }
    }
    const onFullscreenClicked = () => {
      console.log(fullscreen.value)
      ElNotification.warning({
        message: 'The <strong>Fullscreen</strong> feature is not available yet.',
        dangerouslyUseHTMLString: true,
        customClass: 'houston-notification'
      })
    }
    const robotModeSwitchOnClick = () => {
      robotModeSwitch.value = !robotModeSwitch.value
    }
    const activeSectionIndexSelected = computed(() => {
      return store.getters.getActiveSectionIndex
    })

    const previousSectionClick = () => {
      if (activeSectionIndexSelected.value > 0 && activeSectionIndexSelected.value !== -1) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, activeSectionIndexSelected.value - 1)
      }
    }

    const nextSectionClick = () => {
      sectionsCount = 0
      sections.value.forEach((section) => {
        sectionsCount++
      })

      if (activeSectionIndexSelected.value < 7 && activeSectionIndexSelected.value !== -1 && activeSectionIndexSelected.value < sectionsCount - 1) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, activeSectionIndexSelected.value + 1)
      }
    }

    const onSolvingModeSwitched = (value) => {
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, value)
      // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, !value)
    }
    const onShowGridLinesSwitched = (value) => {
      // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, value)
    }
    const onRegenerateSectionClicked = () => {
      store.commit(Mutations.UPDATE_REGENERATE_SECTION_IN_SELECT_MODE, !regenerateSectionInSelectMode.value)
      console.log('regenerate section modal clicked')
    }
    const onKeyDownPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'd') {
        event.preventDefault()
        const activeWidget = computed(() => store.getters.getActiveWidget) as any
        if (!activeWidget.value) return
        const duplicatedWidget = { ...activeWidget.value } as Widget
        const duplicateWidgetUuid = uuid.v4()
        duplicatedWidget.widgetUuid = duplicateWidgetUuid
        duplicatedWidget.position.i = duplicateWidgetUuid

        const activeSectionIndex = computed(() => {
          return store.getters.getActiveSectionIndex
        })
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: activeSectionIndex.value
          },
          widget: duplicatedWidget
        }
        socket.value.emit('COPY_WIDGET', {
          eventType: 'COPY_WIDGET',
          payload: socketPayload
        })
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault()
        solvingModeSwitch.value = !solvingModeSwitch.value
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, solvingModeSwitch.value)
        // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, !solvingModeSwitch.value)
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'g') {
        event.preventDefault()
        showGridLinesSwitch.value = !showGridLinesSwitch.value
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, showGridLinesSwitch.value)
      }
    }
    const callShortcut = (event) => {
      if (event.keyCode === 83 && event.altKey) { // option + s
        solvingModeSwitch.value = !solvingModeSwitch.value
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, solvingModeSwitch.value)
        // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, !solvingModeSwitch.value)
      }

      if (event.keyCode === 77 && event.altKey) { // option + m
        onMobileClicked()
      }

      if (event.keyCode === 71 && event.altKey) { // option + g
        showGridLinesSwitch.value = !showGridLinesSwitch.value
        store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, showGridLinesSwitch.value)
      }
      if (event.keyCode === 68 && event.altKey) { // option + d
        event.preventDefault()
        const activeWidget = computed(() => store.getters.getActiveWidget) as any
        if (!activeWidget.value) return
        const duplicatedWidget = { ...activeWidget.value } as Widget
        const duplicateWidgetUuid = uuid.v4()
        duplicatedWidget.widgetUuid = duplicateWidgetUuid
        duplicatedWidget.position.i = duplicateWidgetUuid

        const activeSectionIndex = computed(() => {
          return store.getters.getActiveSectionIndex
        })
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: activeSectionIndex.value
          },
          widget: duplicatedWidget
        }
        socket.value.emit('COPY_WIDGET', {
          eventType: 'COPY_WIDGET',
          payload: socketPayload
        })
      }
    }
    const readyChecked = ref(false)
    watch(props, (newVal, oldVal) => {
      propTemplate.value = props.template
      if (props.template.status === 2) {
        readyChecked.value = true
      } else {
        readyChecked.value = false
      }
    })

    const groupByEntity = [] as any
    groupByEntity.template = [] as any

    const findEntityInFails = (fails: [], entityUuid: string): any => {
      let entity = [] as any
      fails.forEach((failError: any, key) => {
        if (failError.part.entityUuid === entityUuid) {
          entity = failError.part
        }
        if (typeof failError.parent !== 'undefined' && failError.parent.entityUuid === entityUuid) {
          entity = failError.parent
        }
        if (typeof failError.grandParent !== 'undefined' && failError.grandParent.entityUuid === entityUuid) {
          entity = failError.grandParent
        }
      })

      return entity
    }

    const onReadyClicked = () => {
      // add to loading
      return new Promise((resolve, reject) => {
        store.dispatch(propTemplate.value.status === 1 ? Actions.API_MAKE_READY_TEMPLATE : Actions.API_MAKE_DRAFT_TEMPLATE, propTemplate.value!.activityTemplateUuid).then((response) => {
          if (propTemplate.value.status === 1) {
            propTemplate.value.status = 2
            readyChecked.value = true
            store.commit(Mutations.INCREASE_READY_TEMPLATES_COUNTER, 1)
            store.commit(Mutations.DECREASE_DRAFT_TEMPLATES_COUNTER, 1)
          } else if (propTemplate.value.status === 2) {
            propTemplate.value.status = 1
            readyChecked.value = false
            store.commit(Mutations.INCREASE_DRAFT_TEMPLATES_COUNTER, 1)
            store.commit(Mutations.DECREASE_READY_TEMPLATES_COUNTER, 1)
          }
          return resolve(true)
        }).catch((response) => {
          readyChecked.value = false

          groupByEntity.template = [] as any
          if (response.data.error.code !== 'activityTemplate.entity.ready.could.not.be.validated') {
            Swal.fire({
              width: 600,
              title: 'Could not change status',
              html: response.data.error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Okay',
              customClass: {
                container: 'ready-switch-html-container-error',
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })
            return
          }
          response.data.error.fails.forEach((failError, i) => {
            if (typeof failError.part.entity !== 'undefined') {
              if (failError.part.entity === 'template') {
                if (typeof groupByEntity.template[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template[failError.part.entityUuid] = []
                }
                groupByEntity.template[failError.part.entityUuid].push(failError)
              }
              if (failError.part.entity === 'section') {
                if (typeof groupByEntity.template.sections === 'undefined') {
                  groupByEntity.template.sections = []
                }
                if (typeof groupByEntity.template.sections[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template.sections[failError.part.entityUuid] = []
                }

                groupByEntity.template.sections[failError.part.entityUuid].push(failError)
              }

              if (failError.part.entity === 'widget') {
                if (typeof groupByEntity.template.sections === 'undefined') {
                  groupByEntity.template.sections = []
                }

                if (typeof groupByEntity.template.sections[failError.parent.entityUuid] === 'undefined') {
                  groupByEntity.template.sections[failError.parent.entityUuid] = []
                }

                if (typeof groupByEntity.template.sections[failError.parent.entityUuid].widgets === 'undefined') {
                  groupByEntity.template.sections[failError.parent.entityUuid].widgets = []
                }

                if (typeof groupByEntity.template.sections[failError.parent.entityUuid].widgets[failError.part.entityUuid] === 'undefined') {
                  groupByEntity.template.sections[failError.parent.entityUuid].widgets[failError.part.entityUuid] = []
                }

                groupByEntity.template.sections[failError.parent.entityUuid].widgets[failError.part.entityUuid].push(failError)
              }
            }
          })

          let htmlErrors = '<ul aria-label="">'

          Object.keys(groupByEntity.template).forEach((validKey, key) => {
            if (validKey !== 'sections') {
              // template errors
              groupByEntity.template[validKey].forEach((templateError, tempKey) => {
                htmlErrors += '<li>' + templateError.message + '</li>'
              })
            } else {
              Object.keys(groupByEntity.template.sections).forEach((sectionUuid, skey) => {
                htmlErrors += '<ul aria-label="' + findEntityInFails(response.data.error.fails, sectionUuid).positionOrdinal + ' section: ">'

                Object.keys(groupByEntity.template.sections[sectionUuid]).forEach((sectionKeyInside, skinside) => {
                  // widget errors
                  if (sectionKeyInside !== 'widgets') {
                    htmlErrors += '<li>' + groupByEntity.template.sections[sectionUuid][sectionKeyInside].message + '</li>'
                  } else {
                    Object.keys(groupByEntity.template.sections[sectionUuid].widgets).forEach((widgetUuid, wkey) => {
                      htmlErrors += '<ul aria-label="' + findEntityInFails(response.data.error.fails, widgetUuid).positionOrdinal + ' widget (' + findEntityInFails(response.data.error.fails, widgetUuid).type + '): ">'
                      Object.keys(groupByEntity.template.sections[sectionUuid].widgets[widgetUuid]).forEach((widgetErrorKey, widgetKey) => {
                        htmlErrors += '<li>' + groupByEntity.template.sections[sectionUuid].widgets[widgetUuid][widgetErrorKey].message + '</li>'
                      })
                      htmlErrors += '</ul>'
                    })
                  }
                })
                htmlErrors += '</ul>'
              })
            }
          })
          htmlErrors += '</ul>'

          Swal.fire({
            width: 600,
            title: response.data.error.message,
            html: htmlErrors,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              container: 'ready-switch-html-container-error',
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
          return reject(new Error(response.data.error.message))
        })
      })
    }

    const onMobileClicked = () => {
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE, !mobileChecked.value)
      if (!mobileChecked.value) { // when switching to desktop view mode, trigger resize event to scale builder properly
        const event = new Event('resize')
        window.dispatchEvent(event)
      }
    }

    onMounted(() => {
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE, false)
      // store.commit(Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES, true)
      window.addEventListener('keyup', callShortcut)
      window.addEventListener('keydown', onKeyDownPress)
    })

    onUnmounted(() => {
      window.removeEventListener('keyup', callShortcut)
      window.removeEventListener('keydown', onKeyDownPress)
    })
    return {
      readyChecked,
      solvingModeSwitch,
      showGridLinesSwitch,
      onSolvingModeSwitched,
      onShowGridLinesSwitched,
      solvingModeSwitchOnClick,
      onFullscreenClicked,
      robotModeSwitchOnClick,
      robotModeSwitch,
      onReadyClicked,
      sections,
      onMobileClicked,
      mobileChecked,
      previousSectionClick,
      nextSectionClick,
      onRegenerateSectionClicked,
      regenerateSectionInSelectMode,
      aiSourceChecked,
      aiFeederItems,
      selectedSource,
      selectedSourceType,
      selectedAiFeederItem,
      truncateLabel,
      handleCheckAll,
      handleViewButtonClick,
      currentAccount,
      selectedPages,
      checkAll,
      indeterminate,
      textContentPage,
      textContentVisible,
      textContent,
      regenerateIsLoading,
      customInstructions,
      onRegenerateClicked,
      onKeydownPressed
    }
  }
}
