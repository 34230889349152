import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill = _resolveComponent("quill")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["widget-body", { 'no-drag': !_ctx.isDisabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["widget-body-content tiny-container", { 'white-placeholder': _ctx.widget.specific.color === 'dark' }])
    }, [
      _createVNode(_component_quill, {
        theme: "snow",
        content: _ctx.widgetFound.specific.content,
        "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.widgetFound.specific.content) = $event)),
        options: _ctx.quillOptions,
        "content-type": "text",
        ref: "quillEditor",
        placeholder: "Content",
        spellcheck: "false",
        onUndo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.persistWidget())),
        onEditorChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.persistWidget())),
        onPaste: _cache[3] || (_cache[3] = ($event: any) => (_ctx.persistWidget())),
        "read-only": _ctx.readonly
      }, null, 8, ["content", "options", "read-only"])
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.vcoConfig]
  ])
}