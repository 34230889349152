import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "widget-header" }
const _hoisted_2 = { class: "widget-header-content" }
const _hoisted_3 = {
  key: 0,
  class: "widget-body-content h-100"
}
const _hoisted_4 = { class: "d-flex d-inline-flex align-items-center w-100 h-100 widget-container" }
const _hoisted_5 = {
  key: 1,
  class: "widget-body-content h-100"
}
const _hoisted_6 = { class: "d-flex d-inline-flex align-items-center w-100 h-100 widget-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill = _resolveComponent("quill")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["widget-label", _ctx.widget.specific.color])
    }, "Free Text", 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["widget-header-title tiny-container no-drag d-flex", { 'text-white' : _ctx.widget.specific.color === 'dark' }])
        }, [
          _createVNode(_component_quill, {
            theme: "snow",
            style: {"flex":"0 0 90%"},
            content: _ctx.questionValue,
            "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionValue) = $event)),
            options: _ctx.quillOptions,
            "content-type": "text",
            ref: "quillEditor",
            placeholder: "Add question",
            spellcheck: "false",
            onEditorChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.persistWidget())),
            "read-only": _ctx.solvingMode || _ctx.readonly
          }, null, 8, ["content", "options", "read-only"])
        ], 2)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["widget-body h-100", { 'no-drag': _ctx.solvingMode }])
    }, [
      (_ctx.solvingMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-100 h-100 content-wrapper", { 'rounded' : _ctx.widget.rounded , ['background-' +_ctx.widget.specific.color]: true, ['border-' + _ctx.widget.specific.color] : true }])
              }, [
                _createVNode(_component_quill, {
                  theme: "snow",
                  style: {"height":"100%"},
                  content: _ctx.widgetFound.specific.solution.answer,
                  "onUpdate:content": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.widgetFound.specific.solution.answer) = $event)),
                  options: _ctx.quillOptions,
                  "content-type": "text",
                  ref: "quillEditorAnswer",
                  spellcheck: "false",
                  onEditorChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.persistWidget())),
                  "read-only": false
                }, null, 8, ["content", "options"])
              ], 2)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-100 h-100 content-wrapper", { 'rounded' : _ctx.widget.rounded, ['background-' +_ctx.widget.specific.color]: true, ['border-' + _ctx.widget.specific.color] : true }])
              }, null, 2)
            ])
          ]))
    ], 2)
  ], 64))
}