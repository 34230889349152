
import { computed, defineComponent } from 'vue'
import { Mutations } from '@/store/enums/StoreEnums'
import BuilderWidgetActions from '@/views/mc-admin-builder/BuilderWidgetActions.vue'
import BuilderWidgetHotspot from '@/views/mc-admin-builder/BuilderWidgetHotspot.vue'
import Code from '@/views/mc-admin-builder/Widget/Code.vue'
import TextCardStyled from '@/views/mc-admin-builder/Widget/TextCardStyled.vue'
import Shape from '@/views/mc-admin-builder/Widget/Shape.vue'
import FileUpload from '@/views/mc-admin-builder/Widget/FileUpload.vue'
import Flashcard from '@/views/mc-admin-builder/Widget/Flashcard.vue'
import Heading from '@/views/mc-admin-builder/Widget/Heading.vue'
import ImageCard from '@/views/mc-admin-builder/Widget/ImageCard.vue'
import VideoEmbedded from '@/views/mc-admin-builder/Widget/VideoEmbedded.vue'
import VideoEmbeddedStyled from '@/views/mc-admin-builder/Widget/VideoEmbeddedStyled.vue'
import QuestionShortAnswer from '@/views/mc-admin-builder/Widget/QuestionShortAnswer.vue'
import QuestionSingleChoice from '@/views/mc-admin-builder/Widget/QuestionSingleChoice.vue'
import QuestionMultipleChoice from '@/views/mc-admin-builder/Widget/QuestionMultipleChoice.vue'
import DragAndDropGroup from '@/views/mc-admin-builder/Widget/DragAndDropGroup.vue'
import DragAndDropOrder from '@/views/mc-admin-builder/Widget/DragAndDropOrder.vue'
import ReflectionWidget from '@/views/mc-admin-builder/Widget/ReflectionWidget/ReflectionWidget.vue'
import store from '@/store'
import IconCard from '@/views/mc-admin-builder/Widget/IconCard.vue'
import QuestionShortAnswerAiScored from '@/views/mc-admin-builder/Widget/QuestionShortAnswerAiScored.vue'
import { colorClassMap, hotspotPositionClassMap } from '@/core/helpers/template/widget'
import IframeEmbedded from '@/views/mc-admin-builder/Widget/Iframe/IframeEmbedded.vue'
import ImageView360 from '@/views/mc-admin-builder/Widget/ImageView360/ImageView360.vue'
import QuestionTeamReadiness from '@/views/mc-admin-builder/Widget/QuestionTeamReadiness.vue'
import HotspotTriangle from '@/views/mc-admin-builder/Widget/HotspotWidget/HotspotTriangle.vue'
import HotspotCircle from '@/views/mc-admin-builder/Widget/HotspotWidget/HotspotCircle.vue'
import HotspotSquare from '@/views/mc-admin-builder/Widget/HotspotWidget/HotspotSquare.vue'
import ChattyAvatarWoman from '@/views/mc-admin-builder/Widget/ChattyAvatar/ChattyAvatarWoman.vue'
import ChattyAvatarMan from '@/views/mc-admin-builder/Widget/ChattyAvatar/ChattyAvatarMan.vue'
import ChattyAvatarRobot from '@/views/mc-admin-builder/Widget/ChattyAvatar/ChattyAvatarRobot.vue'
import { isAllowedToRegenerate } from '@/core/mc-admin-helpers/activity-template'
import { isEmpty } from 'lodash'
export default defineComponent({
  name: 'builder-widget',
  methods: { isEmpty },
  emits: ['open-widget-settings', 'widget-delete'],
  props: {
    widgetUuid: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    scaleFactor: { required: false }
  },
  components: {
    QuestionTeamReadiness,
    ImageView360,
    IframeEmbedded,
    QuestionShortAnswerAiScored,
    IconCard,
    BuilderWidgetActions,
    BuilderWidgetHotspot,
    Code,
    TextCardStyled,
    Shape,
    FileUpload,
    Flashcard,
    Heading,
    ImageCard,
    VideoEmbedded,
    VideoEmbeddedStyled,
    QuestionShortAnswer,
    QuestionSingleChoice,
    QuestionMultipleChoice,
    DragAndDropGroup,
    DragAndDropOrder,
    ReflectionPostIt: ReflectionWidget,
    HotspotTriangle,
    HotspotCircle,
    HotspotSquare,
    ChattyAvatarWoman,
    ChattyAvatarMan,
    ChattyAvatarRobot
  },
  setup (props) {
    const socket = computed(() => store.getters.getSocket)
    const hotspotInSelectMode = computed(() => store.getters.getHotspotWidgetInSelectMode)

    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widget = computed(() => store.getters.getWidgetByUuid(props.widgetUuid))
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const getActiveWidgets = computed(() => store.getters.getActiveWidgets)
    const getSelectedWidgets = computed(() => store.getters.getSelectedWidgetsForWidget(hotspotInSelectMode.value))
    const groupedWidgets = computed(() => store.getters.getGroupedWidgets)
    const getExpandedHotspotWidgets = computed(() => store.getters.getExpandedHotspotWidgets)
    const widgetsMobilePosition = computed(() => store.getters.getActiveSectionWidgetsMobilePosition)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const revealMobileHiddenWidgets = computed(() => store.getters.getRevealMobileHiddenWidgets)
    const widgetIndex = computed(() => store.getters.getWidgetIndexByUuid(props.widgetUuid))
    const regenerateSectionInSelectMode = computed(() => store.getters.getRegenerateSectionInSelectMode)
    const regenerateSectionWidgetsList = computed(() => store.getters.getActiveSectionRegenerateSection)

    // const isInSelectableState = computed(() => store.getters.isHotspotInSelectMode)
    // const hotspotSelectionModeWidgetUuid = ref()
    const currentComponent = computed(() => {
      // Split the widget type into words, capitalize the first letter of each word, and join them together to get component name
      return widget.value.type.split('-').map(word => word[0].toUpperCase() + word.slice(1)).join('')
    })

    const handlePersistWidget = (payload) => {
      const widgetFound = computed(() => store.getters.getWidgetByUuid(payload.widget.widgetUuid))
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: widgetFound.value
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }

    const onHotspotExpanded = (widgetUuid) => {
      if (getExpandedHotspotWidgets.value.includes(widgetUuid)) {
        store.commit(Mutations.REMOVE_HOTSPOT_EXPANDED_WIDGETS, widgetUuid)
      } else {
        store.commit(Mutations.ADD_HOTSPOT_EXPANDED_WIDGETS, widgetUuid)
      }
    }

    return {
      colorClassMap,
      hotspotPositionClassMap,
      solvingMode,
      getActiveWidgets,
      groupedWidgets,
      onHotspotExpanded,
      widget,
      widgetIndex,
      mobileViewMode,
      widgetsMobilePosition,
      revealMobileHiddenWidgets,
      handlePersistWidget,
      currentComponent,
      getSelectedWidgets,
      hotspotInSelectMode,
      regenerateSectionInSelectMode,
      regenerateSectionWidgetsList,
      isAllowedToRegenerate
    }
  }
})
