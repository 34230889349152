
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { QuestionSingleChoice } from '@/core/helpers/template/WidgetInterface'
// import Editor from '@tinymce/tinymce-vue'
import { uuid } from 'vue-uuid'
import { ElNotification } from 'element-plus/es'
import { QuillEditor as quill } from '@vueup/vue-quill'

export default defineComponent({
  name: 'question-single-choice',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionSingleChoice, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    quill
    // editor: Editor
  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid))
    const dynamicQuestionValue = computed(() => props.widget.specific.question)
    const questionValue = ref('')
    const quillEditor = ref()
    watch(dynamicQuestionValue, () => {
      questionValue.value = dynamicQuestionValue.value
    })
    onMounted(() => {
      questionValue.value = props.widget.specific.question
    })
    const editorOnChange = () => {
      console.log('changed')
    }
    // const persistTimeout = 0 as any
    const persistWidget = () => {
      console.log('Persisting widget...')
      console.log('quill editor', quillEditor.value.editor.__quill.getText())
      const currentWidgetInstance = widgetFound.value
      currentWidgetInstance.specific.question = quillEditor.value.editor.__quill.getText()
      const alteredContent = widgetFound.value.textToSpeech.content !== retrieveContentForTextToSpeech(widgetFound.value)
      if (alteredContent) {
        widgetFound.value.textToSpeech.content = retrieveContentForTextToSpeech(widgetFound.value)
        widgetFound.value.textToSpeech.state = 'not-processed'
      }

      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidgetInstance
      }
      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
      // clearTimeout(persistTimeout)
      // persistTimeout = setTimeout(() => {
      //
      // }, 1000)
    }

    const onSolutionChanged = (answerUuid) => {
      if (props.readonly) return
      console.log('Solution changed :', answerUuid)
      widgetFound.value.specific.solution = answerUuid
      persistWidget()
    }
    const onAnswerChange = (answerIndex, editor) => {
      console.log('editor here', editor)
      const answer = editor.editor.__quill.getText()
      widgetFound.value.specific.answersList[answerIndex].answer = answer
      persistWidget()
    }
    const onAnswerAdd = () => {
      console.log('Answer option added...')
      if (widgetFound.value.specific.answersList.length > 6) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have more than 7 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      widgetFound.value.specific.answersList.unshift({
        answerUuid: uuid.v4(),
        answer: ''
      })
      persistWidget()
    }

    const onAnswerRemoved = (answerIndex) => {
      console.log('Answer option removed...')
      if (widgetFound.value.specific.answersList.length <= 2) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have less than 2 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const removedAnswerUuid = widgetFound.value.specific.answersList[answerIndex].answerUuid
      widgetFound.value.specific.answersList.splice(answerIndex, 1)
      if (widgetFound.value.specific.solution === removedAnswerUuid) {
        widgetFound.value.specific.solution = null
      }

      persistWidget()
    }

    const retrieveContentForTextToSpeech = (widget) => {
      let content = ''
      content = content + widget.specific.question
      widget.specific.answersList.forEach((answer) => {
        content = content + ' ' + answer.answer + '.'
      })
      return content
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }
    const quillOptions = ref({
      modules: {
        toolbar: false
      }
    })

    return {
      widgetFound,
      solvingMode,
      editorSettings,
      getApiKey,
      persistWidget,
      onSolutionChanged,
      onAnswerAdd,
      onAnswerRemoved,
      editorOnChange,
      questionValue,
      quillOptions,
      quillEditor,
      onAnswerChange
    }
  }
})
