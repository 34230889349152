
import {
  computed,
  defineComponent, inject,
  nextTick,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  onUpdated,
  reactive,
  ref,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { Section } from '@/core/helpers/template/TemplateInterface'
import BuilderWidget from '@/views/mc-admin-builder/BuilderWidget.vue'
import VueGridLayout from 'vue3-grid-layout'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useRouter } from 'vue-router'
import { buildWidgetLocator } from '@/core/helpers/template/template'
import { uuid } from 'vue-uuid'
import vClickOutside from 'click-outside-vue3'
import Draggable from 'vuedraggable'
import { buildTemplateFileUri, isAllowedToRegenerate } from '@/core/mc-admin-helpers/activity-template'
// import LanguageSelector from '@/views/mc-admin-builder/LanguageSelector.vue'
import BuilderSectionMenu from '@/views/mc-admin-builder/BuilderSectionMenu.vue'
import BuilderJitsiMenu from '@/views/mc-admin-builder/BuilderJitsiMenu.vue'
import BuilderWidgetMenu from '@/views/mc-admin-builder/BuilderWidgetMenu.vue'
import BuilderControlsMenu from '@/views/mc-admin-builder/BuilderControlsMenu.vue'
import QuillToolbar from '@/views/mc-admin-builder/QuillToolbar.vue'
// import domtoimage from 'dom-to-image-more'
export default defineComponent({
  name: 'builder-workspace',
  emits: ['open-widget-settings', 'widget-delete', 'workspace-scale-changed', 'widget-group-move', 'widget-group-moved', 'persist-widget'],
  props: {
    section: { type: Object as () => Section | unknown }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    QuillToolbar,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    BuilderWidget,
    draggable: Draggable,
    // LanguageSelector,
    BuilderSectionMenu,
    BuilderJitsiMenu,
    BuilderWidgetMenu,
    BuilderControlsMenu
  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const templateUuid = router.currentRoute.value.params.id
    const socket = computed(() => store.getters.getSocket)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const activeSectionWidgets = computed(() => store.getters.getActiveSectionWidgets)
    const pointOutWidgets = computed(() => store.getters.getPointOutWidgets)
    const activeSectionWidgetsLayout = computed(() => store.getters.getActiveSectionWidgetsLayout)
    const activeSectionWidgetsMobilePosition = computed(() => store.getters.getActiveSectionWidgetsMobilePosition)
    const activeSectionIndex = computed(() => store.getters.getActiveSectionIndex)
    const activeSection = computed(() => store.getters.getActiveSection)
    const sections = computed(() => store.getters.getSections)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)
    const newlyAddedWidgets = computed(() => store.getters.getNewlyAddedWidgets)
    const showGridLines = computed(() => store.getters.getShowGridLines)
    const getActiveWidgets = computed(() => store.getters.getActiveWidgets)
    const groupedWidgets = computed(() => store.getters.getGroupedWidgets)
    const widgetsToCopy = computed(() => store.getters.getWidgetsToCopy)
    const hiddenHotspotWidgets = computed(() => store.getters.getHiddenHotspotWidgets)
    const regenerateSectionWidgetsList = computed(() => store.getters.getActiveSectionRegenerateSection)
    const regenerateSectionInSelectMode = computed(() => store.getters.getRegenerateSectionInSelectMode)
    const customInstructionWidget = ref({})
    watch(regenerateSectionWidgetsList, (newWidgets) => {
      if (newWidgets && newWidgets.length) {
        newWidgets.forEach((widget) => {
          const foundWidget = regenerateSectionWidgetsList.value.filter((w) => w.widgetUuid === widget.widgetUuid)[0]
          customInstructionWidget.value[widget.widgetUuid] = foundWidget.prompt
        })
      }
    })
    function onCustomInstructions (event, widgetUuid) {
      const widgetIndex = regenerateSectionWidgetsList.value.findIndex(el => el.widgetUuid === widgetUuid)
      if (widgetIndex !== -1) {
        const widget = regenerateSectionWidgetsList.value[widgetIndex]
        widget.prompt = event.target.value
        const newRegenerateWidgetList = [...regenerateSectionWidgetsList.value.slice(0, widgetIndex), widget, ...regenerateSectionWidgetsList.value.slice(widgetIndex + 1)]
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: widgetUuid,
          regenerateSectionList: newRegenerateWidgetList
        }
        socket.value.emit('UPDATE_REGENERATE_SECTION_LIST', {
          eventType: 'UPDATE_REGENERATE_SECTION_LIST',
          payload: socketPayload
        })
        // Update the array in place
        store.commit(Mutations.UPDATE_REGENERATE_WIDGETS_LIST, newRegenerateWidgetList)
      }
    }
    const gridLayout = ref()
    const builderWorkspace = ref()
    const builderWorkspaceWrapper = ref()
    const zoom = ref(1)
    const scale = ref(1)
    const builderRowHeight = ref()
    const builderColWidth = ref()
    const builderColNum = 480
    const builderRowNum = 270
    const builderItemMarginX = 0
    const builderItemMarginY = 0
    const builderGridWidth = ref()
    const builderGridHeight = ref()
    const currentAccount = computed(() => store.getters.currentAccount)
    const colorClassMap: { [key: string]: string } = {
      transparent: 'widget-transparent',
      white: 'widget-white',
      orange: 'widget-orange',
      blue: 'widget-blue',
      red: 'widget-red',
      green: 'widget-green',
      purple: 'widget-purple',
      gray: 'widget-gray',
      dark: 'widget-dark'
    }

    // store grid items refs: used in builder layout when dragging new widget
    let itemRefs = [] as any
    const setItemRef = el => {
      if (el) {
        itemRefs.push(el)
      }
    }
    onBeforeUpdate(() => {
      itemRefs = []
    })

    const calculateGroupedWidgetLayout = (groupedWidget, draggedWidgetUuid, newX, newY) => {
      // calculate delta for x and y axes based on the dragged widget
      const draggedWidget = groupedWidgets.value.find(gw => gw.widgetUuid === draggedWidgetUuid)
      const deltaX = draggedWidget.initialX - newX
      const deltaY = draggedWidget.initialY - newY
      // retrieve grouped widget from grid layout
      const widgetLayout = activeSectionWidgetsLayout.value.find(item => item.i === groupedWidget.widgetUuid)
      // calculate how much total space the grouped widget will occupy to prevent it from extending outside the layout
      const totalWidth = groupedWidget.initialX - deltaX + widgetLayout.w
      const totalHeight = groupedWidget.initialY - deltaY + widgetLayout.h
      // calculate new position for the grouped widget relative to the dragged widget
      let newPosX = groupedWidget.initialX - deltaX
      let newPosY = groupedWidget.initialY - deltaY
      // limit position to max column/row count of the grid layout
      newPosX = totalWidth > builderColNum ? builderColNum - widgetLayout.w : newPosX
      newPosY = totalHeight > builderRowNum ? builderRowNum - widgetLayout.h : newPosY
      // avoid negative values
      newPosX = Math.max(newPosX, 0)
      newPosY = Math.max(newPosY, 0)
      return {
        widgetUuid: groupedWidget.widgetUuid,
        newPosX: newPosX,
        newPosY: newPosY,
        w: widgetLayout.w,
        h: widgetLayout.h
      }
    }
    const isModalOpened = ref(false)
    const isItemMoving = ref([]) as any
    let initialWidgetPosition = [] as any
    const emitter = inject('emitter') as any
    emitter.on('create-template', () => {
      // const node = document.getElementById('builder-workspace-wrapper')
      // const data = new FormData()
      // domtoimage.toBlob(node)
      //   .then(function (blob) {
      //     data.append('data', blob)
      //
      //     // axios.post('<Someurl>', data, {
      //     //   headers: {
      //     //     'Content-Type': 'multipart/form-data'
      //     //   }
      //     // })
      //     //   .then(res => {
      //     //     console.log(res)
      //     //   })
      //   })
    })
    emitter.on('regenerate-clicked', () => {
      isModalOpened.value = true
    })
    emitter.on('closing-modal', () => {
      isModalOpened.value = false
    })
    const solvingMode = computed(() => store.getters.getSolvingMode)
    watch(solvingMode, () => {
      if (solvingMode.value) {
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_UUID, -1)
        store.commit(Mutations.UPDATE_HOTSPOT_WIDGET_IN_SELECT_MODE, -1)
        getActiveWidgets.value.forEach(activeWidgetUuid => {
          store.commit(Mutations.REMOVE_ACTIVE_WIDGET, activeWidgetUuid)
          const widgetFound = computed(() => store.getters.getWidgetByUuid(activeWidgetUuid))
          if (widgetFound.value.type === 'hotspot-circle' || widgetFound.value.type === 'hotspot-square' || widgetFound.value.type === 'hotspot-triangle') {
            store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, widgetFound.value.widgetUuid)
          }
        })
      } else {
        activeSectionWidgets.value.forEach(activeSectionWidget => {
          // store.commit(Mutations.REMOVE_ACTIVE_WIDGET, activeSectionWidget)
          // const widgetFound = computed(() => store.getters.getWidgetByUuid(activeWidgetUuid))
          if (activeSectionWidget.type === 'hotspot-circle' || activeSectionWidget.type === 'hotspot-square' || activeSectionWidget.type === 'hotspot-triangle') {
            store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, activeSectionWidget.widgetUuid)
          }
        })
      }
    })
    const widgetMove = (draggedWidgetUuid, newX, newY) => {
      emitter.emit('widget-move', draggedWidgetUuid)
      const draggedWidget = activeSectionWidgets.value.find(gw => gw.widgetUuid === draggedWidgetUuid)

      isItemMoving.value.push(draggedWidgetUuid)
      // needed when the widget is moved and ends in the same position
      if (initialWidgetPosition.length === 0) {
        initialWidgetPosition.push({ x: draggedWidget.position.x, y: draggedWidget.position.y })
      }
      // needed when the widget is moved and ends in the same position
      if (initialWidgetPosition.length && newX === initialWidgetPosition[0].x && newY === initialWidgetPosition[0].y) {
        isItemMoving.value = []
        initialWidgetPosition = []
      }
      const draggedWidgetIsInGroup = groupedWidgets.value.find(gw => gw.widgetUuid === draggedWidgetUuid) !== undefined
      if (groupedWidgets.value.length > 1 && draggedWidgetIsInGroup) {
        for (const groupedWidget of groupedWidgets.value) {
          if (groupedWidget.widgetUuid === draggedWidgetUuid) continue // ignore the widget that is being dragged
          const groupedWidgetLayout = calculateGroupedWidgetLayout(groupedWidget, draggedWidgetUuid, newX, newY)
          emit('widget-group-move', groupedWidgetLayout)
        }
      }
    }

    const widgetMoved = (draggedWidgetUuid, newX, newY) => {
      emitter.emit('widget-moved', draggedWidgetUuid)
      isItemMoving.value = []

      const draggedWidgetIsInGroup = groupedWidgets.value.find(gw => gw.widgetUuid === draggedWidgetUuid) !== undefined
      if (groupedWidgets.value.length > 1 && draggedWidgetIsInGroup) {
        for (const groupedWidget of groupedWidgets.value) {
          if (groupedWidget.widgetUuid === draggedWidgetUuid) continue // ignore the widget that is being dragged
          const groupedWidgetLayout = calculateGroupedWidgetLayout(groupedWidget, draggedWidgetUuid, newX, newY)
          emit('widget-group-moved', groupedWidgetLayout)
        }
      }
      const locatorInstance = buildWidgetLocator(activityTemplate, draggedWidgetUuid)
      const widget = computed(() => store.getters.getWidgetByUuid(draggedWidgetUuid))
      if (widget) {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: locatorInstance.activeSectionIndex,
            activeWidgetIndex: locatorInstance.activeWidgetIndex
          },
          widgetPosition: widget.value.position
        }

        socket.value.emit('EDIT_LAYOUT_SECTION', {
          eventType: 'EDIT_LAYOUT_SECTION',
          payload: socketPayload
        })
      }
    }

    const widgetResize = (widgetUuid) => {
      emitter.emit('widget-move', widgetUuid)
    }
    const widgetResized = (widgetUuid) => {
      emitter.emit('widget-moved', widgetUuid)

      const locatorInstance = buildWidgetLocator(activityTemplate, widgetUuid)
      const widget = computed(() => store.getters.getWidgetByUuid(widgetUuid))
      if (widget) {
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: locatorInstance.activeSectionIndex,
            activeWidgetIndex: locatorInstance.activeWidgetIndex
          },
          widgetPosition: widget.value.position
        }

        socket.value.emit('EDIT_LAYOUT_SECTION', {
          eventType: 'EDIT_LAYOUT_SECTION',
          payload: socketPayload
        })
      }
      // this is obsolete for now: was calling the resize event to scale the text of the widget, and was conflicting with the general builder scaling event
      // setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'))
      // }, 5)
    }

    // change row height when resizing window
    const rowHeightListener = () => {
      builderRowHeight.value = parseFloat(window.getComputedStyle(builderWorkspace.value).height) / builderRowNum
      builderColWidth.value = parseFloat(window.getComputedStyle(builderWorkspace.value).width) / builderColNum
      builderGridWidth.value = (builderColWidth.value + builderItemMarginX) * 20
      builderGridHeight.value = (builderRowHeight.value + builderItemMarginY) * 20
      // console.log(' builderGridHeight', builderGridHeight.value)
    }
    function extractScale (transformMatrix) {
      const matrixToArray = transformMatrix.split(/\s*[(),]\s*/)
      return parseFloat(matrixToArray[1])
    }

    const builderScaleListener = () => {
      let resizingCount = 0
      const MAX_TRIES = 1000
      const SCALE_STEP = 0.001
      const workspace = builderWorkspaceWrapper.value
      const needsDownscaling = (workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)
      const needsUpScaling = (workspace.scrollHeight === workspace.offsetHeight) && (workspace.scrollWidth === workspace.offsetWidth)

      if (needsDownscaling) {
        console.log('Downscaling builder...')
        while (((workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)) && resizingCount < MAX_TRIES) {
          const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
          if (!scaleFactor) break
          const newScaleFactor = scaleFactor - SCALE_STEP
          builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
          scale.value = newScaleFactor
          resizingCount++
        }
        emit('workspace-scale-changed', scale.value)
      }

      if (needsUpScaling) {
        console.log('Up-scaling builder...')
        while ((workspace.scrollHeight === workspace.offsetHeight) && (workspace.scrollWidth === workspace.offsetWidth) && resizingCount < MAX_TRIES) {
          const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
          console.log('scaleFactor', scaleFactor)
          if (!scaleFactor) break
          const newScaleFactor = scaleFactor + SCALE_STEP
          console.log('newScaleFactor', newScaleFactor)
          builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
          scale.value = newScaleFactor
          resizingCount++

          if ((workspace.scrollHeight > workspace.offsetHeight) || (workspace.scrollWidth > workspace.offsetWidth)) { // go back one step
            console.log('Up-scaling generated scroll. Downscaling...')
            const scaleFactor = extractScale(window.getComputedStyle(builderWorkspace.value).transform)
            if (!scaleFactor) break
            const newScaleFactor = scaleFactor - SCALE_STEP
            builderWorkspace.value.style.transform = 'translate(-50%, -50%) scale(' + newScaleFactor + ')'
            scale.value = newScaleFactor
            break
          }
        }
        emit('workspace-scale-changed', scale.value)
      }
    }

    function layoutReady () {
      window.dispatchEvent(new Event('resize'))
    }

    const isMouseHoveringOverJitsi = ref(false)
    const copyPosition = ref({ x: 0, y: 0 })
    const onMouseMove = (e) => {
      const rect = builderWorkspace.value.getBoundingClientRect()
      const scaledXPos = (e.clientX - rect.left) / scale.value
      isMouseHoveringOverJitsi.value = scaledXPos > 1845 && scaledXPos < 1945
      if (mobileViewMode.value || widgetsToCopy.value.length === 0) return

      const widget = computed(() => store.getters.getWidgetByUuid(widgetsToCopy.value[0]))
      const mouseX = e.clientX - rect.left
      const mouseY = e.clientY - rect.top

      // adjust mouse position based on scale factor
      const scaledX = mouseX
      const scaledY = mouseY
      if (!widget.value) return
      if (typeof widget.value.position === 'undefined') return
      copyPosition.value = calculateGridPosition(scaledX, scaledY, widget.value.position)
      // console.log(`Mouse position: (${scaledX}, ${scaledY})`)
    }

    const calculateGridPosition = (mouseX, mouseY, widgetPosition) => {
      const rect = builderWorkspace.value.getBoundingClientRect()
      const xPercent = (mouseX - rect.left) / rect.width
      const yPercent = (mouseY - rect.top) / rect.height
      const col = Math.floor(builderColNum * xPercent)
      const row = Math.floor(builderRowNum * yPercent)
      const maxCol = builderColNum - widgetPosition.w
      const maxRow = builderRowNum - widgetPosition.h
      const newX = Math.min(maxCol, col) < 0 ? 0 : Math.min(maxCol, col)
      const newY = Math.min(maxRow, row) < 0 ? 0 : Math.min(maxRow, row)

      return { x: newX, y: newY }
    }

    const copiedWidget = computed(() => store.getters.copiedWidgetUuid) as any
    function handleKeyDown (event) {
      if (
        (event.metaKey && event.code === 'Backspace') || // cmd + backspace on mac
                (event.shiftKey && event.code === 'Delete') // shift + delete on windows / linux
      ) {
        event.preventDefault() // prevent default behavior (e.g. navigating back)
        if (widgetsToCopy.value.length) {
          emit('widget-delete', widgetsToCopy.value[0])
        }
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        // Copy shortcut is pressed
        if (widgetsToCopy.value.length && !isModalOpened.value) {
          store.commit(Mutations.SET_COPIED_WIDGET, widgetsToCopy.value[0])
        }
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'v' && copiedWidget.value !== '' && !isModalOpened.value) {
        // Paste shortcut is pressed
        const widget = reactive(store.getters.getWidgetByUuid(copiedWidget.value))
        const duplicatedWidget = JSON.parse(JSON.stringify(widget))

        // const duplicatedWidget = reactive(toRefs(duplicatedWidgetRef.value)) as Widget
        duplicatedWidget.widgetUuid = uuid.v4()
        // delete duplicatedWidget.position.i // todo: investigate how the layout syncs this property back into widget position object
        duplicatedWidget.position.i = duplicatedWidget.widgetUuid
        duplicatedWidget.position.x = copyPosition.value.x
        duplicatedWidget.position.y = copyPosition.value.y
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          locator: {
            activeSectionIndex: activeSectionIndex.value
          },
          widget: duplicatedWidget
        }
        socket.value.emit('COPY_WIDGET', {
          eventType: 'COPY_WIDGET',
          payload: socketPayload
        })
      }
    }

    const dragStarted = ref(false)
    const onListWidgetDragged = (event, activeSectionWidgetsMobilePosition) => {
      dragStarted.value = false
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        locator: {
          activeSectionUuid: activeSection.value.sectionUuid
        },
        widgetsMobilePosition: activeSectionWidgetsMobilePosition
      }

      socket.value.emit('EDIT_MOBILE_LAYOUT_ORDER_SECTION', {
        eventType: 'EDIT_MOBILE_LAYOUT_ORDER_SECTION',
        payload: socketPayload
      })
    }

    const sectionChange = (sectionIndex) => {
      // template.value.sections.forEach(templateSection => {
      //   templateSection.widgets.forEach(sectionWidget => {
      //     if (sectionWidget.type === 'hotspot-circle' || sectionWidget.type === 'hotspot-square' || sectionWidget.type === 'hotspot-triangle') {
      //       console.log('adding for ', sectionWidget.widgetUuid)
      //       store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, sectionWidget.widgetUuid)
      //     }
      //   })
      // })
      // activeSectionWidgets.value.forEach(activeSectionWidget => {
      //   // store.commit(Mutations.REMOVE_ACTIVE_WIDGET, activeSectionWidget)
      //   // const widgetFound = computed(() => store.getters.getWidgetByUuid(activeWidgetUuid))
      //   if (activeSectionWidget.type === 'hotspot-circle' || activeSectionWidget.type === 'hotspot-square' || activeSectionWidget.type === 'hotspot-triangle') {
      //     store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, activeSectionWidget.widgetUuid)
      //   }
      // })
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX, sectionIndex)
    }

    const scrollInPosition = (event, sectionIndex) => {
      if (event.target.className.match(/collapsed.*/gi) === null && sectionIndex !== 0) {
        setTimeout(() => {
          builderWorkspace.value.scrollTo({
            top: (75 * sectionIndex)
          })
        }, 100)
      }
    }

    onMounted(() => {
      nextTick(() => {
        builderScaleListener()
      })
      const data = { openedAt: new Date().toISOString() }
      const payload = { data: data, templateUuid: templateUuid }
      watch(currentAccount, (newActivityTemplate, oldActivityTemplate) => {
        store.dispatch(Actions.API_UPDATE_ACTIVITY_TEMPLATE, payload)
      })

      if (currentAccount.value) {
        store.dispatch(Actions.API_UPDATE_ACTIVITY_TEMPLATE, payload)
      }
      // update the template with opened at datetime

      window.addEventListener('resize', rowHeightListener)
      window.addEventListener('resize', builderScaleListener)
      window.addEventListener('keydown', handleKeyDown)
      if (regenerateSectionWidgetsList.value) {
        regenerateSectionWidgetsList.value.forEach((widget) => {
          const foundWidget = regenerateSectionWidgetsList.value.filter((w) => w.widgetUuid === widget.widgetUuid)[0]
          customInstructionWidget.value[widget.widgetUuid] = foundWidget.prompt
        })
      }
      if (dotCanvas.value) {
        const canvas = dotCanvas.value
        context = canvas.getContext('2d')
        vw = getDocumentWidth()
        vh = getDocumentHeight()
        canvas.width = vw
        canvas.height = vh
        window.addEventListener('resize', onResize, false)
        resizeCanvas()
      }
    })
    const dotCanvas = ref<HTMLCanvasElement | null>(null)
    let context: CanvasRenderingContext2D | null = null
    let vw = 0
    let vh = 0

    function getDocumentWidth () {
      return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    }

    function getDocumentHeight () {
      return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }

    function resizeCanvas () {
      if (dotCanvas.value) {
        vw = getDocumentWidth()
        vh = getDocumentHeight()
        dotCanvas.value.width = vw
        dotCanvas.value.height = vh
        drawDots()
      }
    }

    // function drawGrid () {
    //   const cellW = 10
    //   const cellH = 10
    //
    //   context.beginPath()
    //   // vertical lines
    //   for (let x = 0; x <= vw; x += cellW) {
    //     context.moveTo(x, 0) // x, y
    //     context.lineTo(x, vh)
    //   }
    //
    //   // horizontal lines
    //   for (let y = 0; y <= vh; y += cellH) {
    //     context.moveTo(0, y) // x, y
    //     context.lineTo(vw, y)
    //   }
    //
    //   context.strokeStyle = '#cccccc'
    //   context.stroke()
    // }

    function drawDots () {
      if (context && dotCanvas.value) {
        const r = 2
        const cw = 30
        const ch = 30

        context.clearRect(0, 0, vw, vh)

        for (let x = 20; x < vw; x += cw) {
          for (let y = 20; y < vh; y += ch) {
            context.fillStyle = 'gainsboro'
            context.fillRect(x - r / 2, y - r / 2, r, r)
          }
        }
      }
    }

    function onResize () {
      resizeCanvas()
    }
    onUpdated(() => {
      rowHeightListener()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', rowHeightListener)
      window.removeEventListener('resize', builderScaleListener)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('resize', onResize)
    })
    const anyHotspotInSelectMode = computed(() => store.getters.getHotspotWidgetInSelectMode)
    const removeFromCopy = (widgetUuid) => {
      store.commit(Mutations.RESET_WIDGETS_TO_COPY)
    }
    const focusWidget = (widgetUuid) => {
      if (solvingMode.value) {
        // you cannot select a widget in solving mode
        // this is used because it can trigger unexpect behaviours for hotspot

        return
      }

      const widgetClickedFound = computed(() => store.getters.getWidgetByUuid(widgetUuid))
      store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, widgetUuid)
      if (anyHotspotInSelectMode.value !== -1) {
        const widgetFound = computed(() => store.getters.getWidgetByUuid(anyHotspotInSelectMode.value))
        if (widgetUuid !== anyHotspotInSelectMode.value && widgetClickedFound.value.type !== 'hotspot-circle' && widgetClickedFound.value.type !== 'hotspot-triangle' && widgetClickedFound.value.type !== 'hotspot-square' && widgetClickedFound.value.category !== 'action') {
          const indexOf = widgetFound.value.specific.selectedWidgets.indexOf(widgetUuid)
          if (indexOf !== -1) {
            widgetFound.value.specific.selectedWidgets.splice(indexOf, 1)
          } else {
            widgetFound.value.specific.selectedWidgets.push(widgetUuid)
          }
          const socketPayload = {
            templateUuid: activityTemplate.value.activityTemplateUuid,
            widget: widgetFound.value
          }
          socket.value.emit('EDIT_WIDGET', {
            eventType: 'EDIT_WIDGET',
            payload: socketPayload
          })
        }

        return
      }

      // console.log('Focusing widget: ', widgetUuid)
      const widgetIndex = computed(() => store.getters.getWidgetIndexByUuid(widgetUuid))
      const isWidgetDisabled = computed(() => store.getters.isWidgetDisabled(widgetUuid))
      console.log('clicking widget', isWidgetDisabled.value)

      // activeWidget.value = widgetUuid
      store.commit(Mutations.RESET_WIDGETS_TO_COPY)
      if (!isWidgetDisabled.value) {
        store.commit(Mutations.ADD_WIDGET_TO_COPY, widgetUuid)
      }
      store.commit(Mutations.ADD_ACTIVE_WIDGETS, widgetUuid)
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_UUID, widgetUuid)
      store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, widgetIndex.value)
      if (isAllowedToRegenerate(widgetClickedFound.value) && regenerateSectionInSelectMode.value) {
        console.log('regenerateSectionWidgetsList', regenerateSectionWidgetsList.value)
        const updatedRegenerateList = JSON.parse(JSON.stringify(regenerateSectionWidgetsList.value))
        console.log('widgetClickedFound', widgetClickedFound.value)
        const widgetToUpdate = regenerateSectionWidgetsList.value.find(w => w.widgetUuid === widgetClickedFound.value.widgetUuid)
        if (!widgetToUpdate) {
          return
        }
        // the widget is enabled, need to disable it
        if (regenerateSectionWidgetsList.value.some(w => w.widgetUuid === widgetClickedFound.value.widgetUuid && !w.enabled)) {
          const index = updatedRegenerateList.findIndex(obj => obj.widgetUuid === widgetClickedFound.value.widgetUuid)
          if (index !== -1) {
            // Update the enabled field
            updatedRegenerateList[index].enabled = true
          }
        } else {
          return
        }

        // the widget is enabled, need to disable it
        // if (regenerateSectionWidgetsList.value.some(w => w.widgetUuid === widgetClickedFound.value.widgetUuid && !w.enabled)) {
        //   const index = updatedRegenerateList.findIndex(obj => obj.widgetUuid === widgetClickedFound.value.widgetUuid)
        //   if (index !== -1) {
        //     // Update the enabled field
        //     updatedRegenerateList[index].enabled = true
        //   }
        // }
        updatedRegenerateList.sort((a, b) => {
          // Sort enabled widgets first
          if (a.enabled && !b.enabled) {
            return -1 // a should come before b
          } else if (!a.enabled && b.enabled) {
            return 1 // b should come before a
          } else {
            return 0 // no change in order
          }
        })
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: widgetUuid,
          regenerateSectionList: updatedRegenerateList
        }
        socket.value.emit('UPDATE_REGENERATE_SECTION_LIST', {
          eventType: 'UPDATE_REGENERATE_SECTION_LIST',
          payload: socketPayload
        })
        // Update the array in place
        store.commit(Mutations.UPDATE_REGENERATE_WIDGETS_LIST, updatedRegenerateList)
        console.log('updatedRegenerateSectionWidgetsList', updatedRegenerateList)
      }
    }

    // const getActiveWidgets = computed(() => store.getters.getActiveWidgets)
    function dropFocus (widgetUuid) {
      // console.log('solving mode value', solvingMode.value)
      store.commit(Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS, widgetUuid)

      if (anyHotspotInSelectMode.value !== -1) return
      if (getActiveWidgets.value.includes(widgetUuid)) {
        // console.log('Dropping focus for widget: ', widgetUuid)
        store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_UUID, -1)
        // store.commit(Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX, -1)
        store.commit(Mutations.REMOVE_ACTIVE_WIDGET, widgetUuid)
      }
    }

    const handleMultipleSelect = (e, widgetUuid) => {
      if (e.metaKey || e.ctrlKey) { // command / control keys
        // console.log('Multiple select special key pressed: ', widgetUuid)
        if (groupedWidgets.value.some((gw) => gw.widgetUuid === widgetUuid)) {
          store.commit(Mutations.REMOVE_WIDGET_FROM_GROUP, widgetUuid)
        } else {
          store.commit(Mutations.ADD_WIDGET_TO_GROUP, widgetUuid)
        }
      } else {
        // console.log('Multiple select special key NOT pressed: ', widgetUuid)
        store.commit(Mutations.CLEAR_GROUPED_WIDGETS)
      }
    }

    function disableWidgetForRegenerate (widget) {
      if (isAllowedToRegenerate(widget) && regenerateSectionInSelectMode.value) {
        const updatedRegenerateList = JSON.parse(JSON.stringify(regenerateSectionWidgetsList.value))
        const widgetToUpdate = regenerateSectionWidgetsList.value.find(w => w.widgetUuid === widget.widgetUuid)
        if (!widgetToUpdate) {
          return
        }
        // the widget is enabled, need to disable it
        if (regenerateSectionWidgetsList.value.some(w => w.widgetUuid === widget.widgetUuid && w.enabled)) {
          const index = updatedRegenerateList.findIndex(obj => obj.widgetUuid === widget.widgetUuid)
          if (index !== -1) {
            // Update the enabled field
            updatedRegenerateList[index].enabled = false
          }
        } else {
          return
        }

        updatedRegenerateList.sort((a, b) => {
          // Sort enabled widgets first
          if (a.enabled && !b.enabled) {
            return -1 // a should come before b
          } else if (!a.enabled && b.enabled) {
            return 1 // b should come before a
          } else {
            return 0 // no change in order
          }
        })
        const socketPayload = {
          templateUuid: activityTemplate.value.activityTemplateUuid,
          widgetUuid: widget.widgetUuid,
          regenerateSectionList: updatedRegenerateList
        }
        socket.value.emit('UPDATE_REGENERATE_SECTION_LIST', {
          eventType: 'UPDATE_REGENERATE_SECTION_LIST',
          payload: socketPayload
        })
        // Update the array in place
        store.commit(Mutations.UPDATE_REGENERATE_WIDGETS_LIST, updatedRegenerateList)
        console.log('updatedRegenerateSectionWidgetsList', updatedRegenerateList)
      }
    }

    const getExpandedHotspotWidgets = computed(() => store.getters.getExpandedHotspotWidgets)

    const hotspotPositionClassMap: { [key: string]: string } = {
      'top-left': 'hotspot-top-left',
      'top-center': 'hotspot-top-center',
      'top-right': 'hotspot-top-right',
      'center-left': 'hotspot-center-left',
      'center-right': 'hotspot-center-right',
      'bottom-left': 'hotspot-bottom-left',
      'bottom-center': 'hotspot-bottom-center',
      'bottom-right': 'hotspot-bottom-right'
    }

    const sectionBackgroundStyle = computed(() => {
      const style = {}

      if (showGridLines.value && !mobileViewMode.value) {
        style['background-position-x'] = `${0 + 'px !important'}`
        style['background-position-y'] = `${0 + 'px !important'}`
        style['background-size'] = `${builderGridWidth.value + 'px ' + builderGridHeight.value + 'px !important'}`
        style['background-image'] = `${'repeating-linear-gradient(0deg, transparent, transparent ' + (builderGridHeight.value - 1) + 'px, #e8e8e861 ' + (builderGridHeight.value - 1) + 'px, #e8e8e861 ' + builderGridHeight.value + 'px), repeating-linear-gradient(-90deg, transparent, transparent ' + (builderGridWidth.value - 1) + 'px, #e8e8e861 ' + (builderGridWidth.value - 1) + 'px, #e8e8e861 ' + builderGridWidth.value + 'px) !important'}`
      }

      if (activeSection.value.backgroundImage !== undefined && activeSection.value.backgroundImage !== null) {
        const backgroundImage = buildTemplateFileUri(activeSection.value.backgroundImage.attachmentUuid, currentAccount.value.company.name, activeSection.value.backgroundImage)
        style['background-image'] = `url(${backgroundImage})`
        style['background-repeat'] = 'no-repeat'
        style['background-size'] = 'cover'
        style['background-position'] = 'initial'
        style['box-shadow'] = 'unset'
      }

      return style
    })

    const widgetDragend = (widgetType) => {
      emitter.emit('widget-dragend-workspace', widgetType)
    }

    const widgetDrag = (widgetType) => {
      emitter.emit('widget-drag-workspace', widgetType)
    }

    return {
      zoom,
      scale,
      gridLayout,
      builderWorkspace,
      builderWorkspaceWrapper,
      builderColNum,
      builderRowNum,
      builderItemMarginX,
      builderItemMarginY,
      builderRowHeight,
      builderColWidth,
      builderGridWidth,
      builderGridHeight,
      activityTemplate,
      activeSectionWidgets,
      activeSectionWidgetsLayout,
      newlyAddedWidgets,
      groupedWidgets,
      showGridLines,
      colorClassMap,
      sectionBackgroundStyle,
      setItemRef,
      widgetMoved,
      widgetMove,
      widgetResized,
      onMouseMove,
      focusWidget,
      dropFocus,
      handleMultipleSelect,
      getActiveWidgets,
      getExpandedHotspotWidgets,
      hotspotPositionClassMap,
      mobileViewMode,
      onListWidgetDragged,
      dragStarted,
      activeSectionWidgetsMobilePosition,
      sections,
      sectionChange,
      activeSectionIndex,
      layoutReady,
      scrollInPosition,
      isItemMoving,
      widgetResize,
      widgetDragend,
      widgetDrag,
      isMouseHoveringOverJitsi,
      hiddenHotspotWidgets,
      solvingMode,
      pointOutWidgets,
      regenerateSectionInSelectMode,
      regenerateSectionWidgetsList,
      disableWidgetForRegenerate,
      customInstructionWidget,
      onCustomInstructions,
      dotCanvas,
      removeFromCopy
    }
  }
})
