
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { menuInfoWidgets, menuActionWidgets } from '@/core/helpers/template/widgetMenu'
import { useStore } from 'vuex'
import {
  CODE, DRAG_AND_DROP_GROUP, DRAG_AND_DROP_ORDER,
  FILE_UPLOAD,
  HEADING, HOTSPOT_SQUARE, ICONFINDER,
  IFRAME_EMBEDDED,
  IMAGE_CARD, IMAGE_VIEW_360,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SHORT_ANSWER, QUESTION_SHORT_ANSWER_AI_SCORED,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEAM_READINESS, REFLECTION_POST_IT,
  TEXT_CARD_STYLED,
  VIDEO_EMBEDDED
} from '@/core/helpers/template/widgetType'
import { Search } from '@element-plus/icons-vue'
import { Actions } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'builder-widget-menu',
  methods: {
    ICONFINDER () {
      return ICONFINDER
    },
    HOTSPOT_SQUARE () {
      return HOTSPOT_SQUARE
    },
    IMAGE_VIEW_360 () {
      return IMAGE_VIEW_360
    },
    DRAG_AND_DROP_ORDER () {
      return DRAG_AND_DROP_ORDER
    },
    DRAG_AND_DROP_GROUP () {
      return DRAG_AND_DROP_GROUP
    },
    REFLECTION_POST_IT () {
      return REFLECTION_POST_IT
    },
    QUESTION_SHORT_ANSWER_AI_SCORED () {
      return QUESTION_SHORT_ANSWER_AI_SCORED
    },
    QUESTION_SHORT_ANSWER () {
      return QUESTION_SHORT_ANSWER
    },
    QUESTION_TEAM_READINESS () {
      return QUESTION_TEAM_READINESS
    },
    QUESTION_MULTIPLE_CHOICE () {
      return QUESTION_MULTIPLE_CHOICE
    },
    QUESTION_SINGLE_CHOICE () {
      return QUESTION_SINGLE_CHOICE
    },
    IMAGE_CARD () {
      return IMAGE_CARD
    },
    IFRAME_EMBEDDED () {
      return IFRAME_EMBEDDED
    },
    VIDEO_EMBEDDED () {
      return VIDEO_EMBEDDED
    },
    CODE () {
      return CODE
    },
    FILE_UPLOAD () {
      return FILE_UPLOAD
    },
    HEADING () {
      return HEADING
    },
    TEXT_CARD_STYLED () {
      return TEXT_CARD_STYLED
    }
  },
  props: {
    iconfinderWasClicked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['widget-drag', 'widget-dragend', 'show-iconfinder'],
  setup (props, { emit }) {
    const store = useStore()
    const infoWidgets = ref(menuInfoWidgets)
    const actionWidgets = ref(menuActionWidgets)
    const searchInput = ref('')
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const showTooltip = ref(true)
    const onWidgetsSearch = () => {
      infoWidgets.value = menuInfoWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
      actionWidgets.value = menuActionWidgets.filter(i => i.name.match(RegExp(searchInput.value + '.*', 'gi')))
    }
    const clickedIconfinder = ref(props.iconfinderWasClicked)
    const onDragStart = (event) => {
      // needed to hide the green round plus cursor and the "globe" in chrome when dragging
      event.dataTransfer.dropEffect = 'none'
      event.dataTransfer.effectAllowed = 'move'
      const blankCanvas: any = document.createElement('canvas')
      blankCanvas.classList.add('hide-cursor-when-dragging')
      blankCanvas.style.width = '1px'
      blankCanvas.style.height = '1px'
      event.dataTransfer?.setDragImage(blankCanvas, 0, 0)
      document.body?.getElementsByClassName('builder-workspace')[0].appendChild(blankCanvas)
    }
    const onDragEnd = (event) => {
      // needed to hide the green round plus cursor and the "globe" in chrome when dragging
      event.dataTransfer.dropEffect = 'none'
      event.dataTransfer.effectAllowed = 'move'
      const blankCanvas = document.getElementsByClassName('hide-cursor-when-dragging')[0]
      if (blankCanvas) {
        document.body?.getElementsByClassName('builder-workspace')[0].removeChild(blankCanvas)
      }
    }

    const emitter = inject('emitter') as any
    const onClickHandler = (menuItem) => {
      // no click for specific widgets
      if (menuItem.type !== 'iconfinder') {
        return
      }
      clickedIconfinder.value = !clickedIconfinder.value
      emitter.emit('show-iconfinder', clickedIconfinder.value)
    }

    emitter.on('close-iconfinder', () => {
      clickedIconfinder.value = false
    })

    const onDragHandler = (menuItem) => {
      // no drag for specific widgets
      if (menuItem.type === 'iconfinder') {
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Triangle') {
        emit('widget-drag', 'shape-triangle')
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Circle') {
        emit('widget-drag', 'shape-circle')
        return
      }

      emit('widget-drag', menuItem.type)
    }

    const onDragEndHandler = (menuItem) => {
      // no drag for specific widgets
      if (menuItem.type === 'iconfinder') {
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Triangle') {
        emit('widget-dragend', 'shape-triangle')
        return
      }
      if (menuItem.type === 'shape' && menuItem.name === 'Circle') {
        emit('widget-dragend', 'shape-circle')
        return
      }
      emit('widget-dragend', menuItem.type)
    }

    watch(props, (newVal) => {
      clickedIconfinder.value = newVal.iconfinderWasClicked
    })

    const iconFinderIcons = ref([]) as any
    const searchQuery = ref('')
    const currentPage = ref(0)
    const loadMoreLoading = ref(false)
    const iconsLoading = ref(false)
    const showLoadMore = ref(false)
    const handleSearchClick = (v) => {
      currentPage.value = 0
      iconsLoading.value = true
      iconFinderIcons.value = []

      store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: 0, query: searchQuery.value }).then((result) => {
        iconFinderIcons.value = result.payload.data.icons
        iconsLoading.value = false
        if (result.payload.data.total_count - 52 > 0) {
          showLoadMore.value = true
        } else {
          showLoadMore.value = false
        }
      }).catch(() => {
        iconsLoading.value = false
      })
    }
    const loadMore = () => {
      loadMoreLoading.value = true
      store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: currentPage.value + 1, query: searchQuery.value }).then((result) => {
        iconFinderIcons.value = iconFinderIcons.value.concat(result.payload.data.icons)
        currentPage.value = currentPage.value + 1
        if (result.payload.data.total_count - (currentPage.value + 1) * 52 > 0) {
          showLoadMore.value = true
        } else {
          showLoadMore.value = false
        }
        loadMoreLoading.value = false
      }).catch(() => {
        loadMoreLoading.value = false
      })
    }

    function onShowIcons () {
      if (iconFinderIcons.value.length === 0) {
        iconsLoading.value = true
        store.dispatch(Actions.API_ICONFINDER_SEARCH_ICONS, { page: 0, query: 'interface' }).then((result) => {
          iconFinderIcons.value = result.payload.data.icons
          iconsLoading.value = false
          if (result.payload.data.total_count - 52 > 0) {
            showLoadMore.value = true
          }
        }).catch(() => {
          iconsLoading.value = false
        })
      }
    }

    function onDragIcon (icon) {
      emitter.emit('drag-icon', icon)
    }
    function onDragEndIcon (icon) {
      emitter.emit('dragend-icon', icon)
    }

    const handleIconClick = (downloadUrl) => {
      const payload = {
        content: downloadUrl,
        templateUuid: activityTemplate.value.activityTemplateUuid
      }
      store.dispatch(Actions.API_UPLOAD_ICONFINDER_IMAGE, payload).then((result) => {
        console.log('result', result)
      })
    }
    return {
      searchInput,
      infoWidgets,
      actionWidgets,
      activityTemplate,
      clickedIconfinder,
      showTooltip,
      iconFinderIcons,
      searchQuery,
      currentPage,
      loadMoreLoading,
      iconsLoading,
      showLoadMore,
      onWidgetsSearch,
      onClickHandler,
      onDragHandler,
      onDragEndHandler,
      onDragStart,
      onDragEnd,
      Search,
      handleSearchClick,
      loadMore,
      onDragIcon,
      onDragEndIcon,
      handleIconClick,
      onShowIcons

    }
  }
})
