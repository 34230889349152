
import { computed, defineComponent, inject, nextTick, onMounted, ref, watch } from 'vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
// import Editor from '@tinymce/tinymce-vue'
import { StyledTextCard } from '@/core/helpers/template/WidgetInterface'
import vClickOutside from 'click-outside-vue3'
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.core.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { useStore } from 'vuex'
import { Mutations } from '@/store/enums/StoreEnums'
// import type { Quill } from 'quill'
export const styledTextCardBackgroundColors = {
  'green-light': '#BFEDD2',
  green: '#2DC26B',
  'turquoise-dark': '#169179',
  'yellow-light': '#FBEEB8',
  'red-light': '#F8CAC6',
  'purple-light': '#ECCAFA',
  'blue-light': '#C2E0F4',

  yellow: '#F1C40F',
  red: '#E03E2D',
  purple: '#B96AD9',
  blue: '#3598DB',

  orange: '#E67E23',
  'red-dark': '#BA372A',
  'purple-dark': '#843FA1',
  'blue-dark': '#236FA1',
  'grey-light': '#ECF0F1',
  'gray-medium': '#CED4D9',
  gray: '#95A5A6',
  'gray-dark': '#7E8C8D',
  'navy-blue': '#34495E',
  dark: '#1A1C30',
  white: '#FFFFFF',
  transparent: 'transparent'
}

export default defineComponent({
  name: 'text-card-styled',
  props: {
    widget: { type: Object as () => StyledTextCard, required: true },
    readonly: { type: Boolean, default: false },
    resizeInfo: { type: Object },
    scaleFactor: { type: Number, required: false }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    // editor: Editor,
    quill: QuillEditor
  },
  emits: ['persist-widget'],
  setup (props, { emit }) {
    const popoverVisibleFontsize = ref(false)
    const popoverVisibleBackground = ref(false)
    const popoverVisibleColor = ref(false)
    const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']

    const Size = Quill.import('attributors/style/size')
    Size.whitelist = fontSizeArr
    Quill.register(Size, true)
    const content = ref<string>('')
    const showToolbar = ref<boolean>(false)
    const quillEditor = ref<any>(null)
    const toolbar = ref<HTMLDivElement | null>(null)

    const toolbarOptions = { container: '#my-toolbar', size: fontSizeArr }
    // const toolbarOptions = {
    //   theme: 'snow', // or 'bubble' for a bubble theme
    //   modules: {
    //     toolbar: { container: '#my-toolbar', size: fontSizeArr }
    //   },
    //   classes: 'my-custom-class' // custom class added to Quill editor
    // }
    const modules = {
      toolbar: [
        ['bold', 'italic']
        // Add more toolbar options as needed
      ]
    }
    const handleSelectionChange = (range: any) => {
      if (range.range && range.range.length > 0) {
        showToolbar.value = true
        emitter.emit('handle-selection-change', { editor: quillEditor.value, showToolbar: showToolbar.value })
        // nextTick(positionToolbar)
        // positionToolbar()
      } else {
        // if (showToolbar.value === true) {
        showToolbar.value = false

        emitter.emit('handle-selection-change', { editor: quillEditor.value, showToolbar: showToolbar.value })
        // }
      }
    }

    const formatText = (format: string, extra = null) => {
      popoverVisibleFontsize.value = false
      popoverVisibleBackground.value = false
      popoverVisibleColor.value = false
      if (quillEditor.value) {
        if (extra === null) {
          const formatsApplied = quillEditor.value.editor.__quill.getFormat()
          let booleanValue = true
          if (formatsApplied[format]) {
            booleanValue = !formatsApplied[format]
          }
          quillEditor.value.editor.__quill.format(format, booleanValue)
        } else {
          console.log('format', format, extra)
          quillEditor.value.editor.__quill.format(format, extra)
        }
      }
      persistWidget()
    }
    // const getCumulativeTransform = (element) => {
    //   let x = 0
    //   let y = 0
    //   let el = element
    //
    //   while (el) {
    //     const style = window.getComputedStyle(el)
    //     const transform = style.transform
    //
    //     if (transform && transform !== 'none') {
    //       const matrix = new DOMMatrix(transform)
    //       x += matrix.m41
    //       y += matrix.m42
    //     }
    //
    //     el = el.parentElement
    //   }
    //   return { x, y }
    // }
    // const positionToolbar = () => {
    //   console.log('quillEditor.value', quillEditor.value.editor.__quill)
    //   console.log('toolbar.value', toolbar.value)
    //   if (quillEditor.value && toolbar.value) {
    //     const quillInstance = quillEditor.value.editor.__quill
    //     // quillEditor.value.focus()
    //     const range = quillInstance.getSelection()
    //     if (range) {
    //       const bounds = quillInstance.getBounds(range.index, range.length)
    //       if (props.scaleFactor) {
    //         toolbar.value.style.top = `${(bounds.top / props.scaleFactor) - toolbar.value.offsetHeight}px`
    //         toolbar.value.style.left = `${(bounds.left / props.scaleFactor)}px`
    //       }
    //     }
    //   }
    // }
    const dynamicTextValue = computed(() => props.widget.specific.content)
    const store = useStore()
    const textValue = ref('')
    const editorRef = ref()
    const editorInstance = ref()
    const isScrollingUpPossible = ref(false)
    const isScrollingDownPossible = ref(false)
    const scalingNumber = ref(0)
    watch(dynamicTextValue, () => {
      textValue.value = dynamicTextValue.value
      if (quillEditor.value && quillEditor.value.editor.__quill) {
        const quill = quillEditor.value.editor.__quill
        quill.root.innerHTML = dynamicTextValue.value.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2')
        emitter.emit('emitting-position', quillEditor.value.editor.__quill.selection.savedRange)
      }
    })
    const handleEditorSetup = (editor) => {
      editorInstance.value = editor
    }
    const onScrollInsideTheWidget = (event) => {
      const target = quillEditor.value.editor.__quill.container
      isScrollingUpPossible.value = target.scrollTop > 0
      isScrollingDownPossible.value = target.scrollTop < target.scrollHeight - target.clientHeight
    }
    const onHoverTheWidget = (event) => {
      const target = quillEditor.value.editor.__quill.container
      isScrollingUpPossible.value = target.scrollTop > 0
      isScrollingDownPossible.value = target.scrollTop < target.scrollHeight - target.clientHeight
    }
    const onMouseLeaveWidget = (event) => {
      isScrollingDownPossible.value = false
      isScrollingUpPossible.value = false
    }
    const emitter = inject('emitter') as any

    emitter.on('scaling-workspace', (value) => {
      scalingNumber.value = value
    })
    emitter.on('persist-widget-quill', () => {
      persistWidget()
    })
    const isDisabled = ref(true)
    let persistTimeout = 0 as any
    const onDoubleClickEditor = () => {
      if (quillEditor.value.editor.__quill) {
        isDisabled.value = false
        quillEditor.value.editor.__quill.enable(true)

        store.commit(Mutations.RESET_WIDGETS_TO_COPY)
        store.commit(Mutations.RESET_COPIED_WIDGET)

        store.commit(Mutations.ADD_DISABLED_WIDGET, props.widget.widgetUuid)
      }
    }
    onMounted(() => {
      widgetFound.value = dynamicWidget.value
      textValue.value = props.widget.specific.content
      nextTick(() => {
        // quillEditor.value.editor.__quill.enable(false)
        // quillEditor.value.editor.__quill.root.setAttribute('spellcheck', false)
        // quillEditor.value.setHTML(textValue.value)
        if (quillEditor.value && quillEditor.value.editor.__quill) {
          const quill = quillEditor.value.editor.__quill
          quill.enable(false) // Disable the editor
          quill.root.setAttribute('spellcheck', false) // Disable spellcheck
          // quill.root.editor.innerHTML = textValue.value.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2')
          // quill.clipboard.dangerouslyPasteHTML(textValue.value.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2')) // Set the HTML content
          // quillEditor.value.setHTML(textValue.value.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2'))
          quill.root.innerHTML = props.widget.specific.content.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2')
          // console.log('after', textValue.value.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2'))
        }
      })
    })

    const dynamicWidget = computed(() => props.widget)
    watch(dynamicWidget, () => {
      widgetFound.value = dynamicWidget.value
    }, { deep: true })
    const widgetFound = ref({}) as any
    const persistWidget = () => {
      clearTimeout(persistTimeout)
      persistTimeout = setTimeout(() => {
        widgetFound.value.specific.content = textValue.value
        const alteredContent = widgetFound.value.textToSpeech.content !== textValue.value.replace(/(<([^>]+)>)/gi, '')
        if (alteredContent) {
          widgetFound.value.textToSpeech.content = textValue.value.replace(/(<([^>]+)>)/gi, '')
          widgetFound.value.textToSpeech.state = 'not-processed'
        }
        emit('persist-widget', { widget: widgetFound.value })
      }, 1000)
    }

    const editorAdvancedSettings = {
      setup: (editor) => {
        editorInstance.value = editor
        editor.ui.registry.addContextToolbar('textselection', {
          predicate: () => !editor.selection.isCollapsed(),
          items: 'alignleft aligncenter alignright alignjustify forecolor backcolor numlist bullist link hr | bold italic underline strikethrough fontselect fontsizeselect outdent indent',
          position: 'selection',
          scope: 'node'
        })
        editor.on('init', function () {
          // move cursor to end of text
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('NodeChange', () => { // fixes an issue with editor not focusing when it becomes enabled
          if (!editor.getContainer().classList.contains('mce-disabled')) {
            editor.focus()
          }
        })
        editor.on('Focus', () => { // move cursor at the end when focusing
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
        })
        editor.on('FormatApply FormatRemove', function () { // change also for deleting all content
          persistWidget()
        })
        editor.on('ExecCommand', function (e) {
          const eventsToPersist = ['InsertOrderedList', 'InsertUnorderedList', 'indent', 'outdent']
          if (eventsToPersist.includes(e.command)) {
            persistWidget()
          }
        })
        editor.on('keydown', function (e) { // indent/outdent using tab and shift keys
          if (e.key === 'Enter' || e.key === 'Backspace' || e.key === 'Delete') {
            persistWidget()
          }
          if ((e.altKey) && // Check if combination key
                (e.code === 'KeyS')) {
            console.log('preventing default')
            e.preventDefault()
            return
          }
          if (e.keyCode === 9) {
            e.preventDefault()
            if (e.shiftKey) {
              editor.execCommand('outdent')
              persistWidget()
            } else {
              editor.execCommand('indent')
              persistWidget()
            }
          }
        })
      },
      plugins: ['lists', 'paste', 'link', 'hr'],
      inline: true,
      menubar: false,
      toolbar: false,
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      fontsize_formats: '12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt 48pt 72pt',
      font_formats: 'Alegreya=alegreya,serif; Lato=lato,sans-serif; Merriweather=merriweather,serif; Montserrat=montserrat,serif; Open Sans=open sans,sans-serif; Oswald=oswald,sans-serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif; Roboto=roboto,sans-serif;Times New Roman=times new roman,times'
    }
    function containsClassUpward (element, className) {
      while (element) {
        if (element.classList && element.classList.contains(className)) {
          return true
        }
        element = element.parentElement
      }
      return false
    }
    const vcoConfig = {
      handler: () => {
        isDisabled.value = true
        quillEditor.value.editor.__quill.enable(false)
        store.commit(Mutations.CLEAR_DISABLED_WIDGETS)
      },
      middleware: (e) => {
        return (e.target && typeof e.target.className === 'string' && !e.target.className.match(/tox/) && !e.target.className.match(/ql-editor/) && !containsClassUpward(e.target, 'ql-editor'))
      },
      events: ['dblclick', 'click'],
      isActive: true,
      detectIFrame: true,
      capture: false
    }

    return {
      editorAdvancedSettings,
      isDisabled,
      vcoConfig,
      textValue,
      persistWidget,
      getApiKey,
      onScrollInsideTheWidget,
      onHoverTheWidget,
      handleEditorSetup,
      onMouseLeaveWidget,
      isScrollingUpPossible,
      isScrollingDownPossible,
      editorRef,
      content,
      showToolbar,
      quillEditor,
      toolbar,
      handleSelectionChange,
      formatText,
      toolbarOptions,
      modules,
      popoverVisibleFontsize,
      popoverVisibleBackground,
      popoverVisibleColor,
      onDoubleClickEditor
    }
  }
})
