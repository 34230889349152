import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill = _resolveComponent("quill")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["widget-body", { 'no-drag': !_ctx.isDisabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["widget-body-content tiny-container", { 'with-up-arrow' : _ctx.isScrollingUpPossible, 'with-down-arrow' : _ctx.isScrollingDownPossible }]),
      onScroll: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onScrollInsideTheWidget && _ctx.onScrollInsideTheWidget(...args))),
      onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeaveWidget && _ctx.onMouseLeaveWidget(...args))),
      onMousenter: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onHoverTheWidget && _ctx.onHoverTheWidget(...args))),
      ref: "editorRef"
    }, [
      _createVNode(_component_quill, {
        theme: "snow",
        content: _ctx.textValue,
        "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textValue) = $event)),
        "content-type": "html",
        ref: "quillEditor",
        toolbar: _ctx.toolbarOptions,
        onDblclick: _ctx.onDoubleClickEditor,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.persistWidget())),
        onPaste: _cache[2] || (_cache[2] = ($event: any) => (_ctx.persistWidget())),
        onUndo: _cache[3] || (_cache[3] = ($event: any) => (_ctx.persistWidget())),
        onSelectionChange: _ctx.handleSelectionChange
      }, null, 8, ["content", "toolbar", "onDblclick", "onSelectionChange"])
    ], 34)
  ], 2)), [
    [_directive_click_outside, _ctx.vcoConfig]
  ])
}