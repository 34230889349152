
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { QuestionTeamReadiness } from '@/core/helpers/template/WidgetInterface'
// import Editor from '@tinymce/tinymce-vue'
import { uuid } from 'vue-uuid'
import { ElNotification } from 'element-plus/es'
import ContentEditable from '@/components/builder/ContentEditable/ContentEditable.vue'

export default defineComponent({
  name: 'question-team-readiness',
  emits: ['open-widget-settings', 'persist-widget'],
  props: {
    widget: { type: Object as () => QuestionTeamReadiness, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    ContentEditable
  },
  setup (props, { emit }) {
    const dynamicWidget = computed(() => props.widget)
    const store = useStore()
    const placeholder = ref('Add question')
    // const socket = computed(() => store.getters.getSocket)
    // const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const defaultMessage = ref('Submit the answer and wait for everyone to confirm')
    const widgetFound = ref({}) as any
    watch(dynamicWidget, () => {
      widgetFound.value = dynamicWidget.value
      if (dynamicWidget.value.specific.question !== '') {
        placeholder.value = ''
      }
    }, { deep: true })

    const handleQuestionAnswerUpdated = (event, index) => {
      console.log('event.target.textContent', event.target.textContent)
      widgetFound.value.specific.answersList[index].answer = event.target.textContent
      persistWidget()
    }
    const onWidgetQuestionUpdated = (event) => {
      widgetFound.value.specific.question = event.target.textContent
      persistWidget()
    }
    onMounted(() => {
      widgetFound.value = dynamicWidget.value
    })
    const retrieveContentForTextToSpeech = (widget) => {
      let content = ''
      content = content + widget.specific.question
      widget.specific.answersList.forEach((answer) => {
        content = content + ' ' + answer.answer + '.'
      })
      return content
    }

    const persistWidget = () => {
      // clearTimeout(persistTimeout)
      // persistTimeout = setTimeout(() => {
      const alteredContent = widgetFound.value.textToSpeech.content !== retrieveContentForTextToSpeech(widgetFound.value)
      if (alteredContent) {
        widgetFound.value.textToSpeech.content = retrieveContentForTextToSpeech(widgetFound.value)
        widgetFound.value.textToSpeech.state = 'not-processed'
      }
      console.log('Persisting widget ...')
      emit('persist-widget', { widget: widgetFound.value })
      // }, 1000)
    }

    const onSolutionChanged = (answerUuid) => {
      if (props.readonly) return
      console.log('Solution changed :', answerUuid)
      widgetFound.value.specific.solution = answerUuid
      persistWidget()
    }

    const onAnswerAdd = () => {
      console.log('Answer option added...')
      if (widgetFound.value.specific.answersList.length > 6) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have more than 7 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      widgetFound.value.specific.answersList.push({
        answerUuid: uuid.v4(),
        answer: ''
      })
      persistWidget()
    }

    const onAnswerRemoved = (answerIndex) => {
      console.log('Answer option removed...')
      if (widgetFound.value.specific.answersList.length <= 2) {
        ElNotification.warning({
          message: 'The widget <b>may not</b> have less than 2 answer options.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const removedAnswerUuid = widgetFound.value.specific.answersList[answerIndex].answerUuid
      widgetFound.value.specific.answersList.splice(answerIndex, 1)
      if (widgetFound.value.specific.solution === removedAnswerUuid) {
        widgetFound.value.specific.solution = null
      }

      persistWidget()
    }

    const confirmModalVisible = ref(false)
    const onSubmitReadiness = () => {
      confirmModalVisible.value = true
    }

    return {
      widgetFound,
      solvingMode,
      confirmModalVisible,
      getApiKey,
      persistWidget,
      onSolutionChanged,
      onAnswerAdd,
      onAnswerRemoved,
      onWidgetQuestionUpdated,
      defaultMessage,
      handleQuestionAnswerUpdated,
      onSubmitReadiness,
      placeholder
    }
  }
})
