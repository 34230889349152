
import { computed, defineComponent, onMounted, ref, unref, watch } from 'vue'
import { useStore } from 'vuex'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
import { DragAndDropGroup } from '@/core/helpers/template/WidgetInterface'
import { ElNotification } from 'element-plus/es'
import { uuid } from 'vue-uuid'
import Draggable from 'vuedraggable'
// import Editor from '@tinymce/tinymce-vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.core.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default defineComponent({
  name: 'drag-and-drop-group',
  props: {
    widget: { type: Object as () => DragAndDropGroup, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    // editor: Editor,
    draggable: Draggable,
    quill: QuillEditor

  },
  setup (props) {
    const store = useStore()
    const socket = computed(() => store.getters.getSocket)
    const solvingMode = computed(() => store.getters.getSolvingMode)
    const activityTemplate = computed(() => store.getters.getActivityTemplate)
    const widgetFound = computed(() => store.getters.getWidgetByUuid(props.widget.widgetUuid)) ?? []
    const dynamicItemListTitleValue = computed(() => props.widget.specific.itemListTitle)
    const itemListTitleValue = ref('')
    const itemListDescriptionValue = ref('Drag the items bellow in their correct category.')
    const quillOptions = ref({
      modules: {
        toolbar: false
      }
    })
    watch(dynamicItemListTitleValue, () => {
      itemListTitleValue.value = dynamicItemListTitleValue.value
    })
    onMounted(() => {
      itemListTitleValue.value = props.widget.specific.itemListTitle
    })
    const dragStarted = ref(false)

    const persistWidget = () => {
      console.log('Persisting widget...')
      console.log('widgetFound', widgetFound.value)
      const currentWidgetInstance = JSON.parse(JSON.stringify(widgetFound.value))
      const alteredContent = widgetFound.value.textToSpeech.content !== retrieveContentForTextToSpeech(widgetFound.value)
      if (alteredContent) {
        widgetFound.value.textToSpeech.content = retrieveContentForTextToSpeech(widgetFound.value)
        widgetFound.value.textToSpeech.state = 'not-processed'
      }
      currentWidgetInstance.specific.itemListTitle = itemListTitleValue.value
      const socketPayload = {
        templateUuid: activityTemplate.value.activityTemplateUuid,
        widget: currentWidgetInstance
      }
      const currentWidget = unref(currentWidgetInstance)
      console.log('currentWidget', currentWidget)
      socketPayload.widget = currentWidget
      console.log('socketpayload', socketPayload)

      socket.value.emit('EDIT_WIDGET', {
        eventType: 'EDIT_WIDGET',
        payload: socketPayload
      })
    }
    const retrieveContentForTextToSpeech = (widget) => {
      let content = ''
      if (widget && widget.specific && widget.specific.itemListTitle && widget.specific.itemListTitle.trim() !== '') {
        content = widget.specific.itemListTitle.trim() + ': '
      }
      widget.specific.itemList.forEach((item) => {
        if (item.content && item.content.trim() !== '') {
          content += ' ' + item.content.trim() + '.'
        }
      })
      return content
    }
    const onColumnAdded = () => {
      console.log('Adding column...')
      if (widgetFound.value.specific.columnList.length >= 5) {
        ElNotification.warning({
          message: 'A maximum of <strong>5</strong> columns are supported for this widget.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const columnUuid = uuid.v4()
      widgetFound.value.specific.columnList.push({
        columnUuid: columnUuid,
        title: ''
      })
      widgetFound.value.specific.solution.push({
        columnUuid: columnUuid,
        itemList: []
      })
      persistWidget()
    }

    const onColumnRemoved = (columnKey) => {
      console.log('Removing column...')
      if (widgetFound.value.specific.columnList.length <= 1) {
        ElNotification.warning({
          message: 'You may not remove the <strong>last</strong> column.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const column = widgetFound.value.specific.columnList[columnKey]
      const solutionKey = widgetFound.value.specific.solution.findIndex(
        solution => solution.columnUuid === column.columnUuid
      )
      widgetFound.value.specific.solution.splice(solutionKey, 1)
      widgetFound.value.specific.columnList.splice(columnKey, 1)
      persistWidget()
    }

    const onListItemAdded = () => {
      console.log('Adding list item...')
      widgetFound.value.specific.itemList.unshift({
        itemUuid: uuid.v4(),
        content: ''
      })
      persistWidget()
    }

    const onItemListChanged = (itemUuid, updatedItemContent, el: any = null) => {
      // search and replace in solution
      const goodUpdatedContent = el.editor.__quill.getText()
      widgetFound.value.specific.itemList.forEach(item => {
        if (item.itemUuid === itemUuid) {
          item.content = goodUpdatedContent
        }
      })
      const solution = widgetFound.value.specific.solution
      for (let s = 0; s < solution.length; s++) {
        for (let si = 0; si < solution[s].itemList.length; si++) {
          if (solution[s].itemList[si].itemUuid === itemUuid) {
            solution[s].itemList[si].content = goodUpdatedContent
          }
        }
      }
      persistWidget()
    }

    const onListItemRemoved = (itemUuid) => {
      console.log('Removing item with UUID:', itemUuid)
      if (widgetFound.value.specific.itemList.length <= 1) {
        ElNotification.warning({
          message: 'You may not remove the <strong>last</strong> item.',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      // remove from itemList
      const itemKey = widgetFound.value.specific.itemList.findIndex(i => i.itemUuid === itemUuid)
      widgetFound.value.specific.itemList.splice(itemKey, 1)
      // remove from solution in case it exists
      const solution = widgetFound.value.specific.solution
      for (let s = 0; s < solution.length; s++) {
        for (let si = 0; si < solution[s].itemList.length; si++) {
          if (solution[s].itemList[si].itemUuid === itemUuid) {
            solution[s].itemList = solution[s].itemList.filter(sol => sol.itemUuid !== itemUuid)
          }
        }
      }
      persistWidget()
    }
    const getSolutionIndexByItemUuid = (columnUuid, itemUuid) => {
      // Find the column with the specified UUID
      const column = widgetFound.value.specific.solution.find(col => col.columnUuid === columnUuid)

      // If column with specified UUID is found
      if (column) {
        // Find the index of the item with the specified UUID within the column's itemList
        const index = column.itemList.findIndex(si => si.itemUuid === itemUuid)

        // Return the index if item with specified UUID is found within the column
        if (index !== -1) {
          return index
        } else {
          // Handle case when item with specified UUID is not found within the column
          console.error('Item with UUID not found in the specified column')
          return -1 // or any other suitable error handling
        }
      } else {
        // Handle case when column with specified UUID is not found
        console.error('Column with UUID not found')
        return -1 // or any other suitable error handling
      }
    }
    const onListItemDragged = () => {
      console.log('Reordering available items...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemDraggedToColumn = () => {
      console.log('Dragging item to column...')
      dragStarted.value = false
      persistWidget()
    }

    const onItemClonedToColumn = (itemList) => {
      console.log('Cloning item to column...')
      return {
        solutionItemUuid: uuid.v4(),
        itemUuid: itemList.itemUuid,
        content: itemList.content
      }
    }

    const onSolutionItemRemoved = (columnUuid, solutionItemUuid) => {
      console.log('Removing solution item with solution item uuid ', solutionItemUuid)
      const solution = widgetFound.value.specific.solution
      const solutionColumnKey = solution.findIndex(s => s.columnUuid === columnUuid)
      const solutionItemKey = solution[solutionColumnKey].itemList.findIndex(si => si.solutionItemUuid === solutionItemUuid)

      widgetFound.value.specific.solution[solutionColumnKey].itemList.splice(solutionItemKey, 1)
      persistWidget()
    }

    const getSolutionByColumnUuid = (columnUuid) => {
      const solutionIndex = widgetFound.value.specific.solution.findIndex(s => s.columnUuid === columnUuid)
      return widgetFound.value.specific.solution[solutionIndex].itemList
    }

    const editorSettings = {
      setup: (editor) => {
        editor.on('keydown', function (e) {
          if (e.keyCode === 13) e.preventDefault() // prevent enter
        })
      },
      inline: true,
      menubar: false,
      toolbar: false,
      plugins: ['paste'],
      paste_as_text: true,
      paste_block_drop: true, // prevent pasting when dropping -> part of bug that allowed dragging to other widgets
      invalid_elements: 'br',
      paste_preprocess: function (plugin, args) { // replace multiple spaces and trim on paste
        args.content = args.content.replace(/\s\s+/g, ' ').trim()
      }
    }

    const colorClassMap: { [key: string]: string } = {
      white: 'ghost-widget-white',
      orange: 'ghost-widget-orange',
      blue: 'ghost-widget-blue',
      red: 'ghost-widget-red',
      green: 'ghost-widget-green',
      purple: 'ghost-widget-purple',
      gray: 'ghost-widget-gray'
    }

    const getSolutionAvailableItems = () => {
      console.log('Calculating solution available items...')
      let solutionExistingItemsUuids = [] as any
      widgetFound.value.specific.solution.forEach(column => {
        solutionExistingItemsUuids = solutionExistingItemsUuids.concat(column.itemList)
      })

      return widgetFound.value.specific.itemList.filter(
        item => typeof solutionExistingItemsUuids.find(si => si.itemUuid === item.itemUuid) !== 'object'
      )
    }

    return {
      solvingMode,
      dragStarted,
      editorSettings,
      colorClassMap,
      widgetFound,
      getApiKey,
      persistWidget,
      onColumnAdded,
      onColumnRemoved,
      onListItemAdded,
      onItemListChanged,
      onListItemRemoved,
      onListItemDragged,
      onItemDraggedToColumn,
      onItemClonedToColumn,
      onSolutionItemRemoved,
      getSolutionByColumnUuid,
      getSolutionAvailableItems,
      itemListTitleValue,
      itemListDescriptionValue,
      getSolutionIndexByItemUuid,
      quillOptions
    }
  }
})
